



import React, { useContext, useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { myFirestore} from '../../config/ConfigFirebase';
 
import Swal from 'sweetalert2';
import { AuthContext } from '../../auth/authContext';
import { createOrGetConversation, getContacts, getRecentConversationFB, sendGroupMessageByConversations } from '../../helpers/chatFunc';
//import { useGetChatList, useGetUsersChat } from '../../hooks/hooks_chat';
import { ChatMain } from './ChatMain'
import { collection, query,orderBy, onSnapshot } from "firebase/firestore";
//import { ContextMenu } from './ContextMenu'


export const ChatApp = () => {

  
     //Global info user dispatch
     const {currentUser} = useContext(AuthContext);
     //waiting info.
    // let  intervalState;
     const [listState, setListState] = useState('Linchats');
     const [searchText,setSeachTextState] = useState('');
     //this is for items selected
     /*const [selectChat,setSelectChat] = useState({
         selectedChats:[],
         index:0
     });*/
     
     //this other is of a select mulitple
     const [isMultiple,setMultiple] = useState(false);

     //Send group messages
     const showPopupMessage = (listConverIDs)=>{
        Swal.fire({
        input: 'textarea',
        //inputLabel: 'Message',
        title:'Enviar Mensaje',
        //text:name,
        inputPlaceholder: 'Escriba su mensaje aquí...',
        inputAttributes: {
          'aria-label': 'Escriba su mensaje aquí',
          
        },
        
        showCancelButton: true,
        confirmButtonText:'Enviar',
        cancelButtonText:'Cancelar',
        confirmButtonColor:'#0377B8'
       }).then(async(result)=>{
        //function to send message
      
    
        //console.log(result.value);
        if(result.dismiss === 'cancel'){
            if(document.getElementById('sendMessage').classList.contains('activeSecondary')){
                document.getElementById('sendMessage').classList.remove('activeSecondary');
                setMultiple(false);
            }
        }
        else if(result.dismiss === 'backdrop')
        {
            if(document.getElementById('sendMessage').classList.contains('activeSecondary')){
                document.getElementById('sendMessage').classList.remove('activeSecondary');
                setMultiple(false);
            }
        }else{
            if(result.value !== ""){
                //console.log('good',result.value);
                //const {selectedChats} = selectChat;
                console.log('sendMessage to=>',listConverIDs);
                await sendGroupMessageByConversations(listConverIDs,currentUser.id,result.value);
                if(document.getElementById('sendMessage').classList.contains('activeSecondary')){
                    document.getElementById('sendMessage').classList.remove('activeSecondary');
                    setMultiple(false);
                }
                Swal.fire({
                    icon:'success',
                    title:'Entrega exitosa',
                    text:'El mensaje fue enviado con exito',
                });
                //funcion para enviar mensajes por n conversaciones
    
           }
    
           if(result.value === ""){
                Swal.fire({
                    icon:'warning',
                    title:'Aviso',
                    text:'El mensaje no puede ir vació.'
                });
                if(document.getElementById('sendMessage').classList.contains('activeSecondary')){
                    document.getElementById('sendMessage').classList.remove('activeSecondary');
                    setMultiple(false);
                }
            }
        }
       })
    }
     //THIS CHANGE THE ICON AND LIST OF CONTACTS OR CHATS LIST
    const handlerActiveShowContentList = (e)=> {
        e.preventDefault();
      // console.log('change active  => '+ e.target.id);
        const chat = document.getElementById('Linchats');
       const contact = document.getElementById('Lincontacts');
    
       const elem = document.getElementById(e.target.id);
       
        elem.addEventListener('click',()=>{
            if(e.target.id === 'chats' && contact.classList.contains('activePrimary')){
                contact.classList.remove('activePrimary');
                chat.classList.add('activePrimary');
                setListState('Linchats')
                
            }
            if(e.target.id === 'contacts' && chat.classList.contains('activePrimary')){
                setMultiple(false);
                chat.classList.remove('activePrimary');
                contact.classList.add('activePrimary');
                setListState('Lincontacts')
                
            }
        });
    }

    /**GroupSELEC  */
    const handlerActiveGroupChecker = (e) =>{
        e.preventDefault();
        const groupIcon = document.getElementById('groupsSelec')
        //const elem = document.getElementById(e.target.id);
        //elem.addEventListener('click',()=>{
            if(groupIcon.classList.contains('activeSecondary')){
                groupIcon.classList.remove('activeSecondary');
                setMultiple(false);
            }else{
                groupIcon.classList.add('activeSecondary');
                setMultiple(true);
            }
        //})

    }

   //SEARCH CHANGE
    const onchangeSearchText = (e)=>{
        //event.preventDefault();
        //const inputSearch = document.getElementById('buscador');
       

        setSeachTextState(e.target.value);
    }

    //BOx group select
    const onchangeSelectChatBox = async(e)=>{
        e.preventDefault();
        let listConverIDs = [];
        const getAllchecboxSelected = document.querySelectorAll('.ck1');
        console.log('checkboxes=>',getAllchecboxSelected);

        if(listState === 'Lincontacts'){
            //list of contacts
            for(let x = 0; x < getAllchecboxSelected.length; x++){
               
                if(getAllchecboxSelected[x].checked === true){
                    const conversationID = await createOrGetConversation(currentUser.id,getAllchecboxSelected[x].value,currentUser.idCompany);
                    console.log(conversationID);
                    listConverIDs.push(conversationID);
                }
                
            }//end for

        }else{
            //List of Chats
            for(let i = 0; i < getAllchecboxSelected.length; i++){
                if(getAllchecboxSelected[i].checked === true){
                    console.log(getAllchecboxSelected[i].value);
                    listConverIDs.push(getAllchecboxSelected[i].value);
                }
                
            }//end for
        }

        if(listConverIDs.length > 1){
           
            //sendMessage
            if( document.getElementById('groupsSelec').classList.contains('activeSecondary')){
                document.getElementById('groupsSelec').classList.remove('activeSecondary');
                document.getElementById('sendMessage').classList.add('activeSecondary');
            }
               // console.log('chatSelected=>',selectChat.selectedChats);
               // console.log(listConverIDs);
                
                //all code to send message 
                showPopupMessage(listConverIDs);
        }else{
            Swal.fire({
                title:'Aviso',
                text:'Debes al menos seleccionar más de un chat para poder enviar mensaje al grupo.',
                icon:'warning',
                
            });
        }

    }


     //GET CONTACTS
     const [usersState, setUserState] = useState({
        listUsers:[],
        loadingCon:true,
    });
    
    useEffect(  () => {
        let isMounted = true;
        if(listState==='Lincontacts'){
            //onSnapshot()
            getContacts(currentUser.idCompany,currentUser.id,searchText,currentUser.typeUser).then((users)=>{
           
                setUserState({
                 listUsers:users,
                    loadingCon:false,
                });
            });
        }
        return () => {
            isMounted = false;
            };
       },[listState]);
    const {loadingCon, listUsers} = usersState;

    // const {loadingCon, listUsers} = useGetUsersChat(currentUser.idCompany,currentUser.id, currentUser.userType, listState);
    // console.log('listUsers => ',listUsers);


     //List of  GETCHATS
     const [chatList,setChatList] = useState({
        listChats:[],
        loading:true,
     });
     useEffect(()=>{
         let isMounted = true;
        if(listState === 'Linchats') {
         
          
           //intervalState = setInterval(()=>{
                //
                
                /*getConversations(currentUser.id,searchText).then( (conversation) => {
                   
                    setChatList({
                    listChats:conversation,
                    loading:false,
                    })
                 });*/
                 
                getRecentConversationFB(currentUser.id,searchText).then((list)=>{
                     console.log('from FB direclty => ',list);
                     let listOfChats = [];
                     if(list.length === 0){
                        const myquery = query(collection(myFirestore,'Users',currentUser.id,'Conversations') , orderBy("timestamp", "desc"));
                        console.info(myquery);
                        // const subCollectionConvers = await getDocs(myquery);
                          onSnapshot(myquery, (querySnapshot)=>{
                            //console.logg('dasfd');
                            console.log("QuerySnapshot =>",querySnapshot);
                            if(querySnapshot === undefined){
                                listOfChats=[];
                                
                            }else{
                                listOfChats= [];
                                querySnapshot.forEach((doc)=>{
                                    /*querySnapshot.docChanges().forEach((doc)=>{
                                        if(doc.type==="added"){
   
                                        }
                                        if(doc.type === "modified"){
   
                                        }*/
                                    let conversation={
                                        id:doc.id,
                                        conversationID:doc.data()['conversationID'],
                                        image: doc.data()['image'],
                                        lastMessage:doc.data()['lastMessage'],
                                        name: doc.data()['name'],
                                        type:doc.data()['type'],
                                        timestamp:doc.data()['timestamp'],
                                        isCurrentChat:doc.data()['isCurrentChat'],
                                        isCurrentChat2:doc.data()['isCurrentChat2'],
                                        newMsg:doc.data()['newMsg']
                                      
                                     }
                    
                                     listOfChats.push(conversation);
                                });
                            }
                         
                             console.log("LISTCHAT==ONSnapshot==>",listOfChats);
                             setChatList({
                                listChats:listOfChats,
                                loading:false,
                                })
                            
                         });
                     }
                     
                 });
          //  },5000)
       
            
        //console.log(intervalState);
        //   return ()=> clearInterval(interval);
          }
          return () => {
            isMounted = false;
            };
     },[listState,searchText]);
     const {listChats,loading} = chatList;
     //console.log('listChats => ',listChats);

    
       //busqueda
       const search = (event)=>{
        event.preventDefault();
        //clean statelist
        
            //get list if searchText has something
        if(listState==='Linchats') {
            /*if(searchText != ""){
                clearInterval(intervalState);
            }*/
            setChatList({
                listChats:[],
                loading:true,
                })
                getRecentConversationFB(currentUser.id,searchText).then((conversation)=>{
                setChatList({
                    listChats:conversation,
                    loading:false,
                    })
            })
        }
    
        
    }

    const searchContact = (e)=>{
        e.preventDefault();
        setUserState({
            listUsers:[],
            loadingCon:true,
        })
        getContacts(currentUser.idCompany,currentUser.id,searchText,currentUser.typeUser).then((contacts)=>{
            setUserState({
                listUsers:contacts,
                loadingCon:false,
            })
        })
    }
  

     //CHATSELECTED
     const [chatState,setChatStat] = useState('');

 
     const handlerChatInfo = (e)=>{
       e.preventDefault();
        const idChat= e.target.id;
       setChatStat(idChat)
 
     }
     const handlerCreateOrGetChat = (e) =>{
         e.preventDefault();
         const recipentID = e.target.id;
         console.log(recipentID);
        createOrGetConversation(currentUser.id,recipentID,currentUser.idCompany,currentUser.typeUser).then((res)=>{
             console.log(res);
            setChatStat(res);
         })
     }
     //This useEffect is to update the chatconversation messages unreaded
     /*useEffect (()=>{
         if(chatState !== ""){
            updateMessagesUnreaded(currentUser.id,chatState).then((val)=>{

                const respueta = val;
                console.log('status =>',respueta);
            });
         }
        
     },[chatState])*/

 
    //*RENDER LIST/
    const renderListContactsOrChats = () => {
        let viewList= [];
        if(listState === 'Lincontacts'){
                listUsers.forEach((item,index)=>{
                    viewList.push(
                        <li key={item.id} id={index} className='sizeCardItem'>
                            {isMultiple === true ? <input className='ck1 filled-in' type="checkbox" value={item.id} />  : '' }
                            <Link to='' id={`${item.id}`} onClick={handlerCreateOrGetChat}>
                            <div className='row' style={{pointerEvents:'none'}}>
                            <div className='col s4 m4 l3 item-img-prof' style={{pointerEvents:'none'}}>
                           
                                <img src={item.image !== "" ? item.image : `/assets/marvel-thor.jpg`} alt={item.name}  className='icoPicture' style={{pointerEvents:'none'}} />
                          
                            </div>
                            <div className='col s6 m6 l7 item-list-align' style={{pointerEvents:'none'}}>
                            <span style={{fontSize:'11pt', display:'block', color:'#203152', pointerEvents:'none'}}><b>{item.name.substring(0,14)}</b></span>
                            <span style={{color:'#A3A3A9', fontSize:'9pt', display:'block', pointerEvents:'none'}}>{item.status}</span>
                            </div>
            
                            <div className='col s2 m2 l2' style={{pointerEvents:'none'}}></div>
                       
                        
                            </div>
                            </Link>
                        </li>
                    )
                });
        }else{
            listChats.forEach((chat,index)=>{
                //console.log(chat.timestamp._seconds);
                const timestimp = chat.timestamp;
                const date = new Date(timestimp.seconds*1000);
                const format = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

                viewList.push(
                    
                    <li key={chat.conversationID}  className=' list-li sizeCardItem'>
                            {isMultiple === true ? <input className='ck1 filled-in' type="checkbox" value={chat.conversationID}  />  : '' } 
                          <Link to='' className='chatLS' onClick={handlerChatInfo}  id={`${chat.conversationID}`/*,${chat.id},${chat.image},${chat.name},${chat.status} */}>
                        <div className='row' style={{pointerEvents:'none'}}>
                            <div className='col s3  item-img-prof' style={{pointerEvents:'none'}}>
       
                                <img src={chat.image !== "" ? chat.image : `/assets/marvel-thor.jpg`} alt={chat.name}  className='icoPicture ' style={{pointerEvents:'none'}}/>
        
                            </div>
                            <div className='col s6 left' style={{pointerEvents:'none'}}>
                                <span style={{fontSize:'11pt', display:'block', color:'#203152', pointerEvents:'none'}}><b>{chat.name.substring(0,14)}</b></span>
                                <div>
                                <span style={{color:'#A3A3A9', fontSize:'9pt', display:'block', pointerEvents:'none'}}>
                                {/*chat.type === "text" ? chat.status.substring(0,16) : chat.type == "image" ? "Imagen" : "Ubicacón"*/
                                    chat.lastMessage.substring(0,16)
                                }
                                </span>
            
                            </div>
           
                        </div>

                        <div className='col s3 left' style={{pointerEvents:'none'}}>
                            {
                                chat.newMsg > 0 ? 
                                <span className="center bubbleCounter">{/*chat.countUnread*/}</span>
                                : ''
                            }
                            <span style={{color:'#A3A3A9', fontSize:'7pt', display:'block'}}>{format}</span>
                        </div>
       
                        <div className='cols s2' style={{pointerEvents:'none'}}></div>
                        </div>
                    </Link></li>
                )
            });

        }

        return viewList;
    }

    return (
        <div className='bodyScreenChatRow'>
            {/**ContextMenu <ContextMenu />*/}
            <div className='viewListUserChats'>
                <div className='row paddinUserInfo' >
                    <div className='col s12' >
                        <Link to='' className=''><img src={currentUser.image !== '' ? currentUser.image : `/assets/marvel-thor.jpg`} className=" icoPicture responsive-im " alt={currentUser.name} /></Link>
                        <br/> 
                        <h6 className='' style={{color:"white", fontSize:'12pt'}} >{currentUser.name}</h6>     
                        <h6 className='' style={{color:"white", fontFamily:'Roboto', fontSize:'10pt'}}>{currentUser.email}</h6>
                        <div className='right'>
                            <Link to=''  className='margin-icons' id='Lincontacts' onClick={handlerActiveShowContentList}><i className='material-icons ' id='contacts'>people</i></Link>
                            <Link  to='' className='margin-icons activePrimary' id='Linchats' onClick={handlerActiveShowContentList}><i className='material-icons ' id='chats'>question_answer</i></Link>
                            {/*listState === 'Linchats' ?*/  <>
                            <Link to=''  className='margin-icons' id='groupsSelec' onClick={handlerActiveGroupChecker}><i className='material-icons' id='group'>groups</i></Link>
                            <Link to=''  className='margin-icons' id='sendMessage' onClick={onchangeSelectChatBox}><i className='material-icons' id='send'>send</i></Link>
                            </>/*: ''*/}
                        </div>
                         
                    </div>
                  
                </div>
                {/**SEARCH */
                    listState === 'Linchats' ? 
                    <div className='center' style={{background:'#DDDDDF', paddingTop:'5px',paddingBottom:'5px'}}>
                        <form onSubmit={search} className=''>
                            <div className='input-field col s12 m12 l12 serachChat'>
                                <i className='material-icons prefix iconPositionInput'>search</i>
                                <input placeholder='Buscar' id='buscador' name='searchText' type='text' className='inputSerachBorderBottom' style={{color:'#000000'}} onChange={onchangeSearchText} />
                            </div>
                        </form>
                    </div> :
                    <div className='center' style={{background:'#DDDDDF', paddingTop:'5px', paddingBottom:'5px'}}>
                    <form onSubmit={searchContact}>
                        <div className='input-field col s12 m12 l12 serachChat'>
                            <i className='material-icons prefix iconPositionInput'>search</i>
                            <input placeholder='Buscar' id='buscador' name='searchText' type='text' className='inputSerachBorderBottom' style={{color:'#000000'}} onChange={onchangeSearchText} />
                        </div>
                    </form>
                </div>
                }
               
                {/**LIST CHAT OR CONTACTS */}
                <div className='row'>
                    {
                        listState === 'Lincontacts' ? loadingCon &&  <div className="preloader-wrapper big active ">
                        <div className="spinner-layer spinner-white-only">
                          <div className="circle-clipper left">
                            <div className="circle"></div>
                          </div><div className="gap-patch">
                            <div className="circle"></div>
                          </div><div className="circle-clipper right">
                            <div className="circle"></div>
                          </div>
                        </div>
                      </div> : loading &&  <div className="preloader-wrapper big active ">
                                <div className="spinner-layer spinner-white-only">
                                <div className="circle-clipper left">
                                <div className="circle"></div>
                                </div><div className="gap-patch">
                                <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                <div className="circle"></div>
                                </div>
                                </div>
                                </div>
                        //listState === 'Lincontacts' ?  <ChatListUsers {...currentUser} />  : <ChatsList {...currentUser} />
                    }
                        <div className='contentListCC' >
                            
                            <ul className='list'>
                                {
                              
                                    renderListContactsOrChats()
                                }
                            </ul>
                        </div>
                </div>
                {/** */}
            </div>
            {/*CHATBOARD */}
            <div className='viewBoradChat'>
            {/*ChatMain <ChatMain />*/}

                <ChatMain convID={chatState}/>
            </div>
            
            {/**END */}
        </div>
    )
}


