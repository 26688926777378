import {auth, db, myFirebase, myFirestore, myStorage} from '../config/ConfigFirebase';
import firebaseConfig from '../config/ConfigFirebase';
import { collection, query, where, getDocs,orderBy, doc, collectionGroup, DocumentReference, Firestore, addDoc, Timestamp, updateDoc, setDoc } from "firebase/firestore";
import firebase from '../config/global-variables';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { AuthContext } from '../auth/authContext';
import { now } from 'moment';
import Swal from 'sweetalert2';
//{ nameProyectFirebase }
const collectionTypeUser = 'ServiceTypeUser';

const getTypeUser = async() => {
    let listTypeUsers=[];
    try {
        const response =  query(collection(myFirestore,collectionTypeUser),where('TypeUser','>',0),orderBy('TypeUser'));
        const querySnapshot = await getDocs(response);
        
        querySnapshot.forEach((doc)=>{
            // console.log("=======>" + doc.data()['typeUserName']);
            let typeUser={
                id:doc.id,
                typeUser:doc.data()['typeUser'],
                typeUserName: doc.data()['typeUserName'],
                typeUserNameEs:doc.data()['typeUserNameEs'],
            }
            listTypeUsers.push(typeUser);        
        });
    } catch (error) {
        console.log("error=>" + error);
    }

    return listTypeUsers;
  
}

const registerUser = async(email, password, name, imageURL, idCompany, typeUser, active) => {
    try {
        let falg;
       
        // codition to know if we can register a new user about licences
            const result = await getLimitUserActive(idCompany,1);
            console.log("resultado de verificacion = ", result);
        if(result === 1){
                const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/RegisterUser`;
      
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({email: email,password:password}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       console.log(data);
       if(data!==0){
        await registerUserDB(data,name, email, imageURL, idCompany, typeUser, active);
        falg = true;
       }else{
            Swal.fire({
                title:'Advertencia',
                text:'No se pudo registrar el usuario correctamente, verificar los datos o posible error en el servidor.',
                icon:'error'
            });
            falg= false;
       }

            }else{
                 Swal.fire({
                title:'Aviso',
                text:'No puedes dar de alta el usuario, ya que el cupo máximo fue completado, por favor habla con tu empresa, para que se comunique con Advan.',
                icon:'info'
            });
            falg= false;
            }
         
        
        
        
        return falg;
    } catch (error) {
        Swal.fire({
            title:'Error',
            text:'Ocurrio un error al conectarse al servidor.',
            icon:'error'
        });
        return false;
        
    }
}

const registerUserDB = async(uid,name, email, imageURL, idCompany, typeUser, active) => {
    try {
        let ref = doc(myFirestore,"Users",uid);
        await setDoc(ref,
            {

                "email": email, 
                "idCompany": idCompany, 
                "image": imageURL, 
                "name": name, 
                "serviceID": "NA", 
                "status": "available", 
                "lastSeen": Timestamp.now(),
                "TypeUser":  parseInt(typeUser),
                "active": active,
                "tokenWeb":"",
                "token":"",
                "managerID":"",
                "statusService":2

        });
    } catch (error) {
        console.log("Error=> " + error);
    }
    
}

const updateUser = async(id, email, password, name, imageURL, idCompany, typeUser, active) => {
    try {
        await updateUserDB(id, name, email, imageURL, idCompany, typeUser, active);
        return true;
    } catch (error) {
        return false;
    }
}

const updateUserDB = async(id, name, email, imageURL, idCompany, typeUser, active) => {
    try {
        console.log("id=>" + id);
        updateDoc(doc(myFirestore, "Users", id),{
            "TypeUser":  parseInt(typeUser),
            "active": active,
        });
    } catch (error) {
        console.log("Error=> " + error);
    }
    
}


const getLimitUserActive = async(idCompany,web) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getLimitUserActive`;
      
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({idCompany: idCompany,type:web}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       return data;
        
    } catch (error) {
        Swal.fire({
            title:'Error',
            text:'Ocurrio un error al conectarse al servidor. No se pudo acceder a la información',
            icon:'error'
        });
    }
}

export {getTypeUser, registerUser, updateUser};