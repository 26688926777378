import { updateDoc,doc } from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import Swal from "sweetalert2";
import { myFirestore, myStorage } from "../config/ConfigFirebase";
import { getCurrentUser } from "./loginFunc";


const updatePicAndStatus = async(currentUser,statecurrentPhoto,imgState) =>{
    try {

        const date = Date.now();
        const storageRef = ref(myStorage,`/profile_images/${currentUser.name}-${date.toString()}`);
   
    uploadString(storageRef,`${imgState}`,'data_url',{contentType:`${statecurrentPhoto.type}`}).then((res)=>{
       
        console.log('resUpLoad', res);

        getDownloadURL(storageRef).then(async(URL)=>{
               // console.log('URL', URL);
                 //msg.content = URL;
        if(URL !== "" || URL !== null || URL !== undefined){
            const user = doc(myFirestore,'Users',currentUser.id);
            //actualizamos
                updateDoc(user,{
                    image:URL,
                    status:document.getElementById('selectStatus').value
                });
                //obtenemos la nueva informacion del usuario.
                const userData = await getCurrentUser(currentUser.id);
                    //console.log('Current-user: '+userData.email);
                            const logged = {...userData,logged:true};
                        if(!localStorage.getItem('currentUser')){
                            localStorage.setItem('currentUser',JSON.stringify(logged));
                          
                        }else{
                            localStorage.removeItem('currentUser');
                            localStorage.setItem('currentUser',JSON.stringify(logged));
                            
                        }
                        Swal.close();
                        Swal.fire({
                            title:'Exitoso',
                            text:'Actualización de tu foto de perfil y status correctamente.',
                            icon:'success'
                        }).then(function(){
                            window.location.reload();
                        })
        }else{
            Swal.close();
            Swal.fire({
                title:'Upss!',
                text:`La URL de la imagen no pudo ser generada por\nfallo en el servidor de firebase.`,
                icon:'error'
            })
        }
                
          
               
            }).catch(async(error)=>{
                const errorCode = error.code;
                Swal.close();
                Swal.fire({
                    title:'Upss!',
                    text:`La solicitud no pudo ser entregada por codigo error: ${errorCode}.\nFallo en el servidor de firebase.`,
                    icon:'error'
                })
            });

    });
        
    } catch (error) {
        Swal.close();
        const errorCode = error.code;
        Swal.fire({
            title:'Upss!',
            text:`La solicitud no pudo ser entregada por codigo error: ${errorCode}.\nFallo en el servidor de firebase.`,
            icon:'error'
        })
        
    }
}

export {updatePicAndStatus};