

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Navbar } from '../components/ui/Navbar'
import { HomePage } from '../pages/HomePage'

import { ChatPage } from '../pages/ChatPage'
import { EvidencePage } from '../pages/EvidencePage'
import { IncidenciasPage } from '../pages/IncidenciasPage'
import { EvidenceInfoPage } from '../pages/EvidenceInfoPage'
import { IncidenciaInfoPage } from '../pages/IncidenciaInfoPage'
import { MapaPage } from '../pages/MapaPage'
import { RegisterForm } from '../components/Users/RegisterForm'
import { UsersHomeForm } from '../components/Users/UsersHomeForm'
import { EditUserForm } from '../components/Users/EditUserForm'
import { ChangePassPage } from '../pages/ChangePass'
import { PerfilPage } from '../pages/PerfilPage'
import { Notification } from '../components/Notification'
import { UsersHomePage } from '../pages/UsersHomePage'
import { GruposPage } from '../pages/GruposPage'
import { EditUserPage } from '../pages/EditUserPage'


export const DashbordRoot = (showToast) => {
    return (
        <>

        {
            /**
             * showToast={showToast}
                showToast={showToast}
             */
        }
                <Navbar/>
                <main>
                    <Routes>
                  
                 
                        <Route
                        path="/"
                        element={<HomePage  />}
                        />
                        <Route path="chat" element={<ChatPage />}/>
                        <Route path="mapa" element={<MapaPage />} />
                        <Route path="evidencias" element={<EvidencePage />}/>
                        <Route path="evidencias/:evidenceid" element={<EvidenceInfoPage />}/>
                        <Route path="incidencias" element={<IncidenciasPage />}/>
                        <Route path="incidencias/:incidenciaid" element={<IncidenciaInfoPage />}/>
                        <Route path="usuarios/register" element={<RegisterForm />}/>
                        <Route path="usuarios/userEdit/:usuario" element={<EditUserPage />}/>
                        <Route path="usuarios/grupos" element={<GruposPage />}/>
                        <Route path="usersHomeForm" element={<UsersHomeForm />}/>
                        <Route path="userEdit" element={<EditUserForm />}/>
                        <Route path="changePassword" element={<ChangePassPage />}/>
                        <Route path="perfil" element={<PerfilPage />}/>
                        <Route path="usuarios" element={<UsersHomePage />}/>



                 
                    </Routes> 
                </main>
                <Notification />
                <footer></footer>
          
        </>
    )
}
