

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import M from 'materialize-css';
import Swal from 'sweetalert2';
import { AuthContext } from '../../auth/authContext';
import { asignOperadores, getMonitoristas, getOperadores, getOperadoresAsignados } from '../../helpers/grupos';
import { CircleGif } from '../CircleGif';
import { doc } from 'firebase/firestore';
export const GruposForm = () => {
    //currentUser logeado
    const {currentUser} = useContext(AuthContext);
    //lista para operadoer libres seleccionados
    let listSelectOpF =[];
    let listSelectOpA = [];
    //lista para operdadores asignados selecciondos

    //listMonitoristas
    const [listMonitoristas,setListMonitoristas] = useState({
        listMonitoristasarr:[],
        loading:true,
    });
    //listAllOperators
    const [listOperadores,setListOperadores] = useState({
        listOperadoress:[],
        loadingO:true,
    });
    //listAllOperatorsAsignados
    const [listOperadoresasig,setListOperadoresasig] = useState({
        listOpeAsig:[],
        loadingOAsi:true,
    });
    const [changeMonitorita,setMonitorista] = useState('');
    //const isSubscribed = useRef(true);
    //init
    useEffect(() => {
            let isMounted = true;
            const select = document.querySelectorAll('select');
            M.FormSelect.init(select);


            getMonitoristas(currentUser.idCompany,currentUser.id).then((result)=>{
                setListMonitoristas({
                    listMonitoristasarr:result,
                    loading:false,
                })
            })

            getOperadores(currentUser.idCompany,currentUser.id).then((result)=>{
                setListOperadores({
                    listOperadoress:result,
                    loadingO:false,
                })
            })

            setListOperadoresasig({
                listOpeAsig:[],
                loadingOAsi:true
            })
      
            return () => {
                isMounted = false;
                };
    },[listMonitoristas.loading]);
    //func para esuchar el cambio del select
    const handlerChangeSelector = (e) =>{
        e.preventDefault();
        console.log(e.target.value); // get the value = id firebase user
        setListOperadoresasig({
            listOpeAsig:[],
            loadingOAsi:true
        })
        setMonitorista(e.target.value);

        if(e.target.value !== "" || e.target.value !== "0" ){
            getOperadoresAsignados(currentUser.idCompany,e.target.value).then((oper)=>{
                //console.log(oper);
                setListOperadoresasig({
                    listOpeAsig:oper,
                    loadingOAsi:false
                })
            });
        }
    }

 // Funcion para escuhar el clic en algun li de las dos listas

 const handleSelectOperators = async(e) =>{
        e.preventDefault();
      
        //obtenemos todos los operadores de ambas listas
        const  getAllSelecteOperatorsFree = document.querySelectorAll('.opeFree');
        const  getAllSelecteOperAsig = document.querySelectorAll('.opeAsig');
        
        let listOpeFee = [];
        let listOpeAsig=[];
        if(e.target.id == 'rightBTN'){
            
            for(let x = 0; x < getAllSelecteOperatorsFree.length; x++){
                if(getAllSelecteOperatorsFree[x].checked === true){
                    
                  
                    listOpeFee.push(getAllSelecteOperatorsFree[x].value);
                }
            }
           // console.log(listOpeFee.length)
           // console.log(changeMonitorita);
            if(listOpeFee.length === 0 ){
                Swal.fire({
                title:'Aviso',
                text:'Debes al menos seleccionar un operador libre para hacer esta acción.',
                icon:'warning',
                });
                return false;
            }
            if(changeMonitorita === '' || changeMonitorita === '0' ){
                Swal.fire({
                title:'Aviso',
                text:'Debes al menos seleccionar un Monitorista o jefe de flotilla para hacer esta acción.',
                icon:'warning',
                });
                return false;
            }

           await sendListOfOperators(listOpeFee,changeMonitorita);


        }else{

            for(let x = 0; x < getAllSelecteOperAsig.length; x++){
                if(getAllSelecteOperAsig[x].checked === true){
                    
                  
                    listOpeAsig.push(getAllSelecteOperAsig[x].value);
                }
            }
            //console.log(listOpeAsig.length)
            //console.log(changeMonitorita);
            if(listOpeAsig.length === 0 ){
                Swal.fire({
                title:'Aviso',
                text:'Debes al menos seleccionar un operador asignado para hacer esta acción.',
                icon:'warning',
                });
                return false;
            }
            if(changeMonitorita === '' || changeMonitorita === '0' ){
                Swal.fire({
                title:'Aviso',
                text:'Debes al menos seleccionar un Monitorista o jefe de flotilla para hacer esta acción.',
                icon:'warning',
                });
                return false;
            }

            await sendListOfOperators(listOpeAsig,'');

        }

        //console.log(e);

 }


 //function to get send the list of a uid of operatos and move to another asig or free
 const sendListOfOperators= async(listOfOpperators,monitorista) =>{
    Swal.fire({
        title:'Asignando operadores.',
        allowOutsideClick:false,
        allowEscapeKey: false,
        html:'<b>Por favor espere...</b>',
        didOpen: () => {
            Swal.showLoading()
        },
    });

    const updateOrAddManagerID = await asignOperadores(listOfOpperators,monitorista);
    if(updateOrAddManagerID === false){
        Swal.close();
        Swal.fire({
            title:'Error',
            text:'Intentelo mas tarde.',
            confirmButtonText:'Ok',
            icon:'error',
          })

    }else{
        Swal.close();
        Swal.fire({
            title:'Exito',
            text:'Operación realizada correctamente.',
            icon:'success'
        }).then(function(){
            window.location.reload();
        })
    }
 }


    
    const {listMonitoristasarr,loading} = listMonitoristas;
    //console.log(listMonitoristasarr);
    const {listOperadoress,loadingO} = listOperadores;
    const {listOpeAsig,loadingOAsi} = listOperadoresasig;
   // console.log(listOperadoress);


    //Render Lista de operadores libres
    const listOfOperatorsFree = ()=>{
        let listOper =[];
        if(listOperadoress.length > 0){
            listOperadoress.forEach((operator,index)=>{
                listOper.push(
                    <>
                    <li key={operator.id} id={index} className='sizeCardItem ' >
                    {/*isMultiple === true ? <input className='ck1 filled-in' type="checkbox" value={operator.id} />  : '' */}
                    <input className='opeFree filled-in' type="checkbox" value={operator.id} />
                    <Link to='' id={`${operator.id}`} >
                    <div className='row' style={{pointerEvents:'none'}}>
                    <div className='col s4 m4 l3 item-img-prof imgCenterOper' style={{pointerEvents:'none'}}>
                   
                        <img src={operator.image !== "" ? operator.image : `/assets/marvel-thor.jpg`} alt={operator.name}  className='icoPicture' style={{pointerEvents:'none'}} />
                  
                    </div>
                    <div className='col s6 m6 l7 item-list-align textNameOpercenter' style={{pointerEvents:'none'}}>
                    <span style={{fontSize:'11pt', display:'block', color:'#203152', pointerEvents:'none'}}><b>{operator.name.substring(0,20)}</b></span>
                    
                    </div>
    
                   
               
                
                    </div>
                    </Link>

                </li>
                <li className="divider"></li></>
                
                );
            });



        }else{
            listOper =[];
        }

        return listOper;

    }

    //lista render operadores asigandados por monitorista
    const listOfOperatorsAssignados = ()=>{
        let listOperFree =[];
        if(listOpeAsig.length > 0){
            listOpeAsig.forEach((operator,index)=>{
                listOperFree.push(
                    <>
                    <li key={operator.id} id={index} className='sizeCardItem '>
                    {/*isMultiple === true ? <input className='ck1 filled-in' type="checkbox" value={operator.id} />  : '' */}
                    <input className='opeAsig filled-in' type="checkbox" value={operator.id} />
                    <Link to='' id={`${operator.id}`} className='' >
                    <div className='row' style={{pointerEvents:'none'}}>
                    <div className='col s4 m4 l3 item-img-prof imgCenterOper' style={{pointerEvents:'none'}}>
                   
                        <img src={operator.image !== "" ? operator.image : `/assets/marvel-thor.jpg`} alt={operator.name}  className='icoPicture' style={{pointerEvents:'none'}} />
                  
                    </div>
                    <div className='col s6 m6 l7 item-list-align textNameOpercenter textNameLeftOperAsig' style={{pointerEvents:'none'}}>
                    <span style={{fontSize:'11pt', display:'block', color:'#203152', pointerEvents:'none'}}><b>{operator.name.substring(0,20)}</b></span>
                    
                    </div>
    
                   
               
                
                    </div>
                    </Link>

                </li>
                <li className="divider"></li></>
                
                );
            });



        }else{
            listOperFree =[];
        }

        return listOperFree;

    }

    const validateListOfAsignados = () =>{
        if(changeMonitorita !== "" || changeMonitorita !== '0'){
        return listOpeAsig.length > 0 ? listOfOperatorsAssignados(): <li key={0}>Sin Operadores</li>
        }else{
          return  <li key={0}>Seleciona un monitorista o jefe de flotilla</li>
        }
    }

 



  return (
    <div className='containerUser' style={{padding:'3%'}}>
       
      
                <div className='row center'><h4>Asignar Operadores</h4></div>
              
                
                <form className='col s12 m12 l12'>
                    <div className='row'>
                    

                        <div className="input-field white lighten-5 inputRegisterUser">
                            <i className="tiny material-icons prefix iconPositionInput "></i>
                            <select name="typeUser" 
                            
                            required onChange={handlerChangeSelector}>
                                <option key='0' value='0' selected disabled>Selec. Monitor/Jefe Flotilla</option>
                                
                                {
                                 
                                    listMonitoristasarr.map((user)=>{
                                        //console.log(user);
                                        return <option key={user.id} value={user.id}>{user.name}</option>
                                    })
                                   
                                }
                            </select>
                            
                        </div>
                      

                      

                    </div>
                    <div className='contentDivs'>
                       
                        <div className="white divsListOperators " >
                            <div className='center'><h4>Operadores libres</h4></div>
                                  <ul style={{padding:'10px'}}>
                                      {
                                          
                                          loadingO ===true ? <div className='row center'><CircleGif /></div> : listOfOperatorsFree()
                                      }
                                </ul> 
                        </div>

                        <div className='center'>
                            <br></br>
                            <button className='btn ' id='rightBTN' onClick={handleSelectOperators}><i className='material-icons' style={{pointerEvents:'none'}}>chevron_right</i></button>
                            <br></br>
                            <br></br>
                            <button className='btn' id='leftBTN' onClick={handleSelectOperators}><i className='material-icons' style={{pointerEvents:'none'}}>chevron_left</i></button>
                        </div>
                        

                        <div className="white divsListOperators " >
                        <div className='center'>
                            <h4>Operadores asignados</h4>
                                      <ul style={{padding:'10px'}}>
                                        {
                                            validateListOfAsignados()
                                        }
                                      </ul>
                        </div>
                                   
                        </div>
                    </div>
                    <div className='registerDiv btnActReg-cente' style={{textAlign:'center'}}>
                        {/*<button 
                            className='btn waves-effect waves-light btnFromRegisterCreate' 
                           
                            type='button' name='Crear'>
                                Actualizar
                        </button> */}
                        <Link to='/usuarios' className='btn btnFromLogin'  id='backP' style={{background:'#5E5E68'}}>Regresar</Link>
                      
                        
                    </div>
                </form>
                      
         
      
    </div>
  )
}
