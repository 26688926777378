
import React, { createRef, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {  useState } from 'react/cjs/react.development';

import M from 'materialize-css';
//import { getListenNewMessages } from '../../helpers/chatFunc';
import { useChatCurrentConversation } from '../../hooks/hooks_chat'
import { AuthContext } from '../../auth/authContext';
import {myFirestore, myStorage} from '../../config/ConfigFirebase';
import { onSnapshot, doc, updateDoc, arrayUnion, getDoc }  from "firebase/firestore";
import {ref, getDownloadURL,uploadString} from 'firebase/storage';
import Picker from 'emoji-picker-react';
import Swal from 'sweetalert2';

import { ImagePop } from '../ImagePop';
import { MapPopup } from '../MapPopup';
import { getLocationByChat, sendNotification } from '../../helpers/chatFunc';
//import {firebaseNotificationSend} from '../../config/global-variables';



//import { doc, query,refEqual, where } from '@firebase/firestore';


//recibir un objeto de idConversation, y datos del usuario name,status,

export const ChatLivingRoom = ({idConversation}) => {

    useEffect(()=>{
        let modalMate = document.querySelectorAll('.modal');
        M.Modal.init(modalMate);
    },[])
  
     const {currentUser} = useContext(AuthContext);
   
   // const loading = true; 
    const {conversation} = useChatCurrentConversation(currentUser.id,idConversation);
  
    const [chats, setChats]  = useState([]);
    const [chatID,setChatID] = useState('');
    const [location,setLocation] = useState({});

    
    //const [imageState, setImageState] = useState('');
    const [members,setMembers] = useState([]);
    
    const [inputStr, setInputStr] = useState('');

    const [showPicker, setShowPicker] = useState(false);
    const messageEnd = createRef();
    const [newChat, setNewChat] = useState({type:'',content:'',timestamp:'',status:0, senderID:''});
    //const navigate = useNavigate();

   
    useEffect( ()=>{
        let isMounted = true;
        setChatID(idConversation);
       
        return () => {
            isMounted = false;
            };
    },[conversation.conversationID])

    //GET Current & Listen CHATs
    useEffect ( ()=>{
        
        const unsubcribe =  onSnapshot(doc(myFirestore,'Conversations',idConversation),(documentSnapshot) => {
               
            const messagesCome = documentSnapshot.data().messages;
            const miembrosChat = documentSnapshot.data().members;
            //console.log(messagesCome)
            setMembers([]);
            setChats([]);
            setMembers(miembrosChat)
            setChats(messagesCome)
        });
       
        
        return ()=> unsubcribe();
     },[chatID,idConversation])

     console.log(conversation);

     const scrolltoBootm = () =>{
         messageEnd.current.scrollIntoView({})
     }

     //scrolldown last message
     useEffect( ()=>{
        let isMounted = true;
         scrolltoBootm();
         return () => {
            isMounted = false;
            };
     },[chats])

     /**
      * 
      * @returns userLocations
      */
     const handlerLocation = (e) =>{
         e.preventDefault();
         getLocationByChat(conversation.id).then((val)=>{
             if(val !== {}){
                 setLocation({});
                 //console.log('LOcation=> ',val);
                 setLocation(...val);
             }
         });
         console.log('LOCATION=>',location);
     }

     //render list of messages
     const renderMessages = () =>{
         let viewListMessages= [];
         if(chats.length > 0){
             chats.forEach((message,index) => {

               
                const timestimp = message.timestamp;
                const date = new Date(timestimp.seconds*1000);
                const format = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                //console.log(format);
               
                    
                //const dateFormat = Moment(Number(message.timestamp));//Moment(new Date(message.timestamp * 1000)).format('DD/MM/YYYY HH:mm:ss');
                //console.log(date);
                 viewListMessages.push(
                     <div className='MessageBox' key={index}>
                        
                            <div className='ChatMessage '>
                                <div className={message.senderID === currentUser.id ? "RightBubble z-depth-2" : "LeftBubble z-depth-2"  }>
                                    {
                                      message.senderID === currentUser.id ? <span className="MsgName white-text">Yo</span>:<span className="MsgName grey-text">{conversation.name}</span>
                                    }
                                    {
                                        message.type === 'text' ? 
                                        <div style={{width:'100%',height:'auto', display:'inline-block'}}><p style={{display:'inline-block', width:'100%',height:'auto', fontWeight:'700', overflow:'hidden', overflowWrap:'break-word', wordWrap:'break-word'}}>
                                        {message.message.includes('https://') ? <a href={message.message} target='_blank'>{message.message}</a>: message.message}
                                        </p>
                                        
                                        </div>
                                        : message.type === 'image' 
                                        ? <div className='center'><img src={message.message} alt={'picture'} className='' width='80%'  id='myImg' onClick={handlerShowPicModal}/>
                                        </div>
                                        :<div className='center'></div>
                                    }
                                    <div style={{height:'5px'}}>
                                    <span className="MsgDate right">{format}</span>
                                    </div>
                                    
                                    
                                </div>
                                
                            </div>
                            
                        
                     </div>
                 )
             });
         }else{
             viewListMessages=[];
             //mostrar sin mensajes
         }

       // console.log('messages=>',viewListMessages);
         return viewListMessages;

     }//endRENDErListMEssages

     //sendMessage Text
     const sendMessageText = async(e) =>{
        e.preventDefault();
        const type = 'text';
        const senderID = currentUser.id;
        const msg = newChat;
        const date = Date.now();
        const transformDate = new Date(date);
        //console.log('Date msg => ',transformDate.getTime()) 1671234134854353
        msg.type = type;
        msg.senderID = senderID;
        msg.timestamp = transformDate; // Thu Dec 10 2021 12:06:90 PM
        msg.content = inputStr;
        if(inputStr !== ""){
            const newMessage = doc(myFirestore,'Conversations/',chatID);
            const union = arrayUnion(...[{
            "message":msg.content,
            "senderID": msg.senderID,
            "timestamp": msg.timestamp,
            "type": msg.type,
            "status": msg.status,
        }]);
        
        
        

      /*updateDoc(newMessage,
        {'messages':});*/
       
            updateDoc(newMessage,{messages:union});
            let reciber= '';
            if(members[0]!== currentUser.id){
                reciber = members[0];
            }
            if(members[1]!== currentUser.id){
                reciber = members[1];
            }


        /* ////////////////////////////////////////////////
         * crear las columas o actualizar dicho datos
         */
        
        const getQuery = doc(myFirestore,'Users',reciber,'Conversations', senderID);
        const infoRecentConver = await getDoc(getQuery);
        const getReciber = doc(myFirestore,'Users',reciber);
        const infoReciber = await getDoc(getReciber);
        //query(collection(myFirestore,'Users',reciber,'Conversations'),where('conversationID','==',chatID));
        console.info('Reciber =>',infoReciber);
        if(infoRecentConver.exists()){
            if(currentUser.typeUser === 2 &&  infoReciber.data()['TypeUser'] === 3){
                if(infoRecentConver.data()['isCurrentChat'] === true){
                    updateDoc(getQuery,{
                        "newMsg": 0
                    });
                }else{
                    updateDoc(getQuery,{
                        "newMsg": 1,
                        "isCurrentChat":false,
                    });
                }
            }
            if(currentUser.typeUser === 3 && infoReciber.data()['TypeUser'] === 2){
                if(infoRecentConver.data()['isCurrentChat2'] === true){
                    updateDoc(getQuery,{
                        "newMsg": 0
                    });
                }else{
                    updateDoc(getQuery,{
                        "newMsg": 1,
                        "isCurrentChat2":false,
                    });
                }
            }
        }
        /*const infoConv = await getDoc(getQuery);
        const inforeicber = doc(myFirestore,'Users',reciber);
        const getReicber = await getDoc(inforeicber);
        ////////////////////////////////////////////////////////
        if(infoConv.exists()){
            if(currentUser.typeUser === 2){
                if(infoConv.data()['isCurrentChat'] === true){
                    updateDoc(query,{
                        "newMsg": 0
                    });
                }else{
                    updateDoc(query,{
                        "newMsg": 1,
                        "isCurrentChat":false,
                    });
                }
            }
            if(currentUser.typeUser === 3 && getReicber.data()['TypeUser'] === 2){
                if(infoConv.data()['isCurrentChat2'] === true){
                    updateDoc(query,{
                        "newMsg": 0
                    });
                }else{
                    updateDoc(query,{
                        "newMsg": 1,
                        "isCurrentChat2":false,
                    });
                }
            }
        }*/
        //////////////////////////////////////////////////

          await sendNotification(currentUser.name,reciber, msg.content);
        //call the api-reference notification.
        
        setNewChat({type:'',content:'',timestamp:'',status:0, senderID:''});
        setInputStr('');
        
        }else{
            Swal.fire({
                title:'Alerta',
                text:'No puedes enviar mensajes vacios.',
                confirmButtonText:'Entendido',
                icon:'warning',
            })
        }
        //send the message to the current chat.
     }
     //sendImage & validate
     const sendImageMessgage = async(e) =>{
        e.preventDefault();
       // console.log('Files',e.target.files[0]);
        const type = 'image';
        const senderID = currentUser.id;
        const msg = newChat;
        const date = Date.now();
        const transformDate = new Date(date);
        console.log('Date msg => ',date)
        msg.type = type;
        msg.senderID = senderID;
        msg.timestamp = transformDate;
        msg.content = '';
        if(e.target.files && e.target.files[0]){
            const currentPhoto = e.target.files[0]; //current photo
            //const prefixFileType = e.target.files[0].type.toString();// type jpg or png
            const reader = new FileReader();
            //const fileByteArray = [];
           reader.readAsDataURL(currentPhoto);

             //reader.readAsArrayBuffer(currentPhoto);
            reader.onloadend = (e)=>{
                //console.log('renderResult',e.target.result);
                if(e.target.readyState === FileReader.DONE){
                   /* const arrayBuffer = e.target.result;
                    const array = new Uint8Array(arrayBuffer);
                    for(const a in array){
                        fileByteArray.push(a);
                    }*/
                    Swal.fire({
                        title:'Mensaje',
                        text:'Enviando mensaje.',
                        didOpen:()=>{
                            Swal.showLoading()
                        },
                        timer:6000,
                        allowOutsideClick:false,
                        
                    })
                    //const resultado =  e.target.result.toString().split(',')[1]
                   //console.log('SUBSTRING',resultado);
                   const storageRef = ref(myStorage,`/messages/${currentUser.id}/images/${currentPhoto.name}`);
           
            uploadString(storageRef,`${e.target.result}`,'data_url',{contentType:`${currentPhoto.type}`}).then((res)=>{
               
                console.log('resUpLoad', res);

                getDownloadURL(storageRef).then((URL)=>{
                        console.log('URL', URL);
                         //msg.content = URL;
                        
                        const newMessage = doc(myFirestore,'Conversations/',chatID);
                        const union = arrayUnion(...[{
                        "message":URL,
                        "senderID": msg.senderID,
                        "timestamp": msg.timestamp,
                        "type": msg.type,
                        "status": msg.status,
                        }]);
        
     
                        updateDoc(newMessage,{messages:union});
                        let reciber= '';
                        if(members[0]!== currentUser.id){
                            reciber = members[0];
                        }
                        if(members[1]!== currentUser.id){
                            reciber = members[1];
                        }
                      sendNotification(currentUser.name,reciber, msg.content).then((res)=>{
                        console.log(res);
                      });
                        //call the api-reference notification.

                        setNewChat({type:'',content:'',timestamp:'',status:0, senderID:''});
                        setInputStr('');
                    })

            });
                    //setImageState(resultado);
                    //console.log(imageState)
                    
               }
               //return re.target.result;
            }
            
        }
       console.log(msg.content);
  
        //send the message to the current chat.
       /* */

     }
     //emogies
     const onEmojiClick = (event, emojiObject) => {
        setInputStr(prevInput => prevInput + emojiObject.emoji);
        setShowPicker(false);
      };


    const fileClick = (e)=>{
        e.preventDefault();
        const el = document.getElementById('imgInput');
      
        if(el){
            el.click();
        }
    }

    return (
        
       <>
       {/*CHAT ROOM */}
       {
        
       }
       <div className='viewChatBoard '>
           {/*HEADER CHATROOM */}
           <div className='headerChatBoard z-depth-2' >
                <div className=' left ' style={{marginLeft:'15px', marginTop:'10px',flex:'0.3', pointerEvents:'none'}}>
                   
                        <img src={conversation.image !== '' ? conversation.image : `/assets/marvel-thor.jpg`} alt={'hhh'}  className='icoPicture' />
                        
                </div>
                <div className='headerTextChat left' >
                            <span style={{fontSize:'12pt', display:'block', color:'#000000', fontWeight:'900', position:'relative', top:'12%', pointerEvents:'none'}}><b>{conversation.name}</b></span>
               
                            <span style={{color:'#75747D', fontSize:'9pt', display:'block', fontWeight:'600', pointerEvents:'none', paddingTop:'5px'}}>{conversation.status === "" ? 'Disponible' : conversation.status  }</span>
                </div>
                <div className='' style={{flex:'1'}} >
                    <div style={{width:'80px',height:'60px', marginLeft:'25%'}} className='center'>
                    
                        <Link to="" id={conversation.id} data-target="modal1" className=' modal-trigger' style={{ position:'relative', top:'8px' }} onClick={handlerLocation}><img src={`/assets/newMap.png`} alt='maps' className='responsive-img' style={{width:'40px',height:'40px'}}/></Link>
                    </div>
                </div>
           </div>
           {/*CHATLIST VIEW ROOM*/}
           <div className='viewListContentChat'>
               
              
               {
                /* loadingUp === false ? <div className='center'><CircleGif /></div> :*/  renderMessages()
               }
               <div ref={/*loadingUp && */messageEnd} />
              
           </div>
           {/*viewButtomCHATROOM */}
           <div className='viewBottom'>
           
                        {
                            showPicker && <Picker pickerStyle={{ width: '280px', maxwidth:'280px', top:' -200px' }} onEmojiClick={onEmojiClick}/>
                        }  
                    <div className=' center elemePositionFormIcons'style={{flex:'0.6'}}>
                    <i className='material-icons small iconsInputs' onClick={fileClick}>attach_file</i>
                    <input accept='image/*' type='file' id='imgInput' style={{width:'0px'}} onChange={sendImageMessgage}/>
                        {/*<Link to='' className='iconFormMsn' onClick={sendImageMessgage}><i className='material-icons small iconsInputs'>attach_file</i></Link>*/}
                        <Link to='' onClick={()=> setShowPicker(val => !val)} className='iconFormMsn'><i className='material-icons small iconsInputs'>insert_emoticon</i></Link>
                        
                    </div>

                    <div className=' center elemePositionForm' style={{flex:'2'}}>
                        <div className='input-field inputMessage'>
                            <textarea className='txtAreaBorder-None'  type='text' name='txtmessgage' id='txtmessgage' placeholder='Escribe tu mensaje aquí.' style={{color:'#75747D'}} value={inputStr} onChange={e=> setInputStr(e.target.value) }></textarea>
                        </div>
                    </div>

                    <div className=' center elemePositionFormIcons'style={{flex:'0.6'}}>
                        <Link to='' onClick={sendMessageText} className='iconFormMsn'><i className='material-icons small iconsInputs'>send</i></Link>
                    </div>
             
            </div>
        </div>

        {/*MODAL PIC */}
        <ImagePop />
        {/**MODAL MAP */}
        <MapPopup latitude={/*20.214526*/location.latitud} longitude={/*-101.122280*/location.longitud} infoData={location}/>
       </>
    )
}

const handlerShowPicModal = (e)=>{
    e.preventDefault();
    console.log(e.target.src);
    const modal = document.getElementById("myModalPic");
    //const img  = document.getElementById("myImg");
    const modalImg = document.getElementById("img01");
    const captionText = document.getElementById("caption");
    modal.style.display='block';
    modalImg.src = e.target.src;
    captionText.innerHTML = e.target.alt;

}

const handlerShowModalMap = (e)=>{
    e.preventDefault();
}




