

import React, { useEffect, useState } from 'react'
import { getImgEvidence } from '../helpers/evidenceFunc';


//idcurrentUSer to filter =>
const useGetimgEvidence = (serviceDetailID,idCompany) => {
   const [stateImgEvidences, setStateImgEvidence] = useState({
       listImgEvidence:[],
       loadingImg:true,
   });

   //effectData
   useEffect(()=>{
    setStateImgEvidence({
        listImgEvidence:[],
        loadingImg:true,
    });
    getImgEvidence(serviceDetailID,idCompany).then((result)=>{
            
            setStateImgEvidence({
                listImgEvidence:result,
                loadingImg:false,
            });
        })
   },[serviceDetailID]);

   return stateImgEvidences;
}

export{
    useGetimgEvidence,
}
