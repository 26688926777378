
//cambiar en diferentes ramas de entornos.
const nameProyectFirebase = 'bluewayproduction-71e4c';
const apiKeyFirebase = 'AIzaSyCJ84AVwWjP_Evwg58bB34VZmNZr6mF0v8' ;
const messageSenderId= '815738203427';
const appId = '1:815738203427:web:406ac058d593ac0f60f096';
const measurementId = 'G-ZEF7EXF48H';
const firebaseNotificationSend = 'https://fcm.googleapis.com/fcm/send';
const keyPairCloudMessageWeb = 'BGIV2a9slPnWFdeaUM-kG8VgzwxsZIEakpWWcwYFR63Ldm_danLwU0JmW8P3fA_S476MpwDJy637psCOAx5PM-c';
//cloud messagin
const keyMessaging = 'AAAAve3JLSM:APA91bHUT3fuOJSCVQ3s92yjUzLdaTJr4hph-btaefdGfUgp_53qEtqxsdXH0mW3cW-_3CWRKBchQOyMgreR41Iw_Aaxv_Q4jcn7ksXK0F-pMx_Yj-oPZMj7xCQ6q9ah-sDIczyknGlJ';
    const firebase = {
        "nameProyectFirebase":nameProyectFirebase,
        "apiKeyFirebase":apiKeyFirebase,
        "messageSenderId":messageSenderId,
        "appId":appId,
        "measurementId":measurementId,
        "keyMessaging":keyMessaging,
        "firebaseNotificationSend":firebaseNotificationSend,
        "keyPairCloudMessageWeb":keyPairCloudMessageWeb
    };
  export default  firebase ;
  

