import {initializeApp } from 'firebase/app';
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';
import {getDatabase} from 'firebase/database';
//import {onBackgroundMessage } from 'firebase/messaging/sw';
import {onMessage,getMessaging} from 'firebase/messaging'
import Swal from 'sweetalert2';
import firebase from '../config/global-variables';

//{apiKeyFirebase,appId,measurementId,messageSenderId,nameProyectFirebase}

const config ={
    apiKey: firebase.apiKeyFirebase,
    authDomain: `${firebase.nameProyectFirebase}.firebaseapp.com`,
    databaseURL: `https://${firebase.nameProyectFirebase}.firebaseio.com`,
    projectId: firebase.nameProyectFirebase,
    storageBucket: `${firebase.nameProyectFirebase}.appspot.com`,
    messagingSenderId: firebase.messageSenderId,
    appId: firebase.appId,
    measurementId: firebase.measurementId
}




export const myFirebase = initializeApp(config)
export const auth = getAuth(myFirebase)
export const myFirestore = getFirestore(myFirebase)
export const myStorage = getStorage(myFirebase)
export const db = getDatabase(myFirebase)
export const messaging = getMessaging(myFirebase);
//
export const signInEmailAndPassword = 
async (email, password) => {
    try {

      const user  = await signInWithEmailAndPassword(auth, email,password);
      console.log(user.user.uid);

      let uid;

      if(user.user.uid !== "" || user.user.uid !== null){
        uid = user.user.uid;
      }
      //auth.signInWithEmailAndPassword(email, password);
      return uid;
    } catch (err) {
     // console.error(err);
     Swal.fire({
       icon:'error',
       title:'Opps',
       text:'No se encontro el usuario, por favor intente con una cuenta existente.',
       confirmButtonText:'Ok',
     })
      //console.error(err.message);
    }
  };

 

export const logOut = async()=>{
   await auth.signOut();
   if(localStorage.getItem('currentUser')){
     localStorage.removeItem('currentUser');
     const userData = {logged:false};
     localStorage.setItem('currentUser',JSON.stringify(userData));
   }

}

export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });





