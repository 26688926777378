
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
//import { userCreditial } from '../../config/ConfigFirebase';
import { sinIn } from '../../helpers/loginFunc';
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/type';
import Swal from 'sweetalert2';
import { forgetPass } from '../../helpers/passwordFunc';


export const ForgetPassWord = () => {

    const [email, setEmail] = useState("");
    //const [password, setPassword] = useState("");
    //const navigate = useNavigate();
    const {dispatch} = useContext(AuthContext);


    //Login
    const headlerLogin = async()=>{
        const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if((email === null || email === "")){
            //console.log("El correo /o contraseña no pueden ser vacios.");
            
             

            Swal.fire({
              title:'Aviso',
              text:'El correo no pueden ser vacio.',
              confirmButtonText:'Ok',
              icon:'warning',
            })
            return false;
        }
     
        if( (email === null || email === "")){
            //console.log("El correo /o contraseña no pueden ser vacios.");
            
            Swal.fire({
              title:'Aviso',
              text:'El correo debe ser introducido.',
              confirmButtonText:'Ok',
              icon:'warning',
            })
            return false;
        }

        if( (email !== null || email !== "")){
            //console.log("El correo /o contraseña no pueden ser vacios.");
            if(!email.match(regExp)){
                Swal.fire({
                    title:'Aviso',
                    text:'El correo introducido no es valido',
                    confirmButtonText:'Ok',
                    icon:'warning',
                  })
                  return false;
            }
           
        }
        await forgetPass(email);
        const inputEmail = document.getElementById('email');
        inputEmail.value = '';
        setEmail('');

        //Swal.showLoading();
       /*const userDat =await fo
            if(userDat.logged === true){
               // Swal.close();
               // const action = {type:types.login,userData:userDat}
     
                //dispatch(action)

                 /*navigate('/',{
                    replace:true,
                })*/
                //alert que diga que se envio la informacion con un success y que revice su correo electronico.

            /* }else{
                Swal.close();
                Swal.fire({
                    title:'Error',
                    text:'No se puedo enviar la información, para la recuperacion de contraseña.\nError al conectar al servidor de Firebase.',
                    confirmButtonText:'Ok',
                    icon:'error',
                  })
             }*/
      
    }

    return (
        <div className='Container row'>
        <div className=' contentForm'>
            
            <div className='col s12 m12 l12 center'>

                <div className='logoAppDiv'>
                    
                </div>

                <div className='grettingsDiv'>
                    <span>Contraseña</span>
                    
                    <span>Olvidate tu contraseña.</span>
                </div>
              
                        <form className='col s12 m12 l12'>
                            <div className='row'>
                                <div className="input-field  blue-grey lighten-5 formInput">
                                    <i className="tiny material-icons prefix iconPositionInput ">person</i>
                                    <input placeholder="advan@gmail.com" type="email" className="validate sizeInput" value={email} onChange={(e)=>setEmail(e.target.value)} id='email'/>
                                    
                                </div>

                            </div>
                           
                            <div className='accessDiv'>
                                <button className='btn waves-effect waves-light btnFromLogin' type='button' name='login' onClick={headlerLogin}>Solicitar cambió</button>
                                <p><Link className='links' to='/login'>Iniciar Sesión</Link></p>
                            </div>
                        </form>
                      
                        

                       
               
            </div>
            
         
            
        </div>
        </div>
     
    )
}

/**
 *  <div className='registerDiv'>
                        <span className='spanLRH' >¿Aún no tienes una cuenta?</span> <a className='links'  href='#'>Crear cuenta</a>
                        </div>
 */
