

import React from 'react'
import { ChatApp } from '../components/Chat/ChatApp'
import '../css/Chat.css'


export const ChatPage = () => {
    return (
      
            <ChatApp/>
         
     
    )
}