

import React, {useState} from 'react';
import {GoogleMap, Marker, useLoadScript ,InfoWindow} from '@react-google-maps/api';
import { Link } from 'react-router-dom';





export const MapPopup = ({latitude,longitude,infoData}) => {

  const [activeMarker, setActiveMarker] = useState(null);
   
  console.log(infoData);
   if(latitude === undefined && longitude === undefined ){
      latitude= 21;
      longitude= -100;
    }
  if(infoData === {}){
    infoData = [];
  }


    const { isLoaded } = useLoadScript({
                         
        googleMapsApiKey: "AIzaSyCUuTHJGUQ8kkpNFzDB7vjuS8vixaI3o58",
      })

    
    
    const containerStyle = {
        width: '100%',
        height: '500px'
      };

      const center = {
        lat: 21,//20.214526,
        lng: -100//-101.122280,
      };
      
      
      const handlerActiveMarker = (marker) =>{
        //marker.preventDefault();
        console.log(marker);
        if (marker === activeMarker) {
          return;
        }
        setActiveMarker(marker);
      }

      

     const renderMap = ()=>{
      console.log(React.version);
         if(!isLoaded){
            return <div>EL Mapa no se puede cargar, ocurrio un error.</div>;
         }else{
            return <GoogleMap
         zoom={8}
         mapContainerStyle={containerStyle}
         onClick={()=> setActiveMarker(null)}
         center={{ lat: latitude, lng: longitude }}
         onLoad={(map)=>{
            const bounds = new window.google.maps.LatLngBounds({ lat: latitude, lng: longitude });
            map.fitBounds(bounds);
           // map.setZoom(5);
         }}
      
         //onUnmount={onUnmount}
         >
         
          <Marker 
          
            key={infoData.userUid}
           position={{ lat: latitude, lng: longitude }}
           onClick={()=>{
             //e.preventDefault();
            handlerActiveMarker(infoData.userUid)
           } } 
        >

          {
            activeMarker === infoData.userUid ? ( 
            <InfoWindow 
            
            onCloseClick={()=>setActiveMarker(null)}>
              <div className='formatInfoMarker'>
                <span style={{fontSize:'14pt', fontWeight:'bold'}}>Operador: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{infoData.user_name}</span>
                <br/>
                <br/>
                <span style={{fontSize:'14pt', fontWeight:'bold'}}>Unidad: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{infoData.vehicleNumber}</span>
                <br/>
                <br/>
 
              
                <span style={{fontSize:'14pt', fontWeight:'bold'}}>Fecha: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{infoData.dateString}</span>
           
              </div>
             
            </InfoWindow> ): null
          }
          
        </Marker> 
          
         
         </GoogleMap>
         } 

     }
      
    return (
        <div  id="modal1" className="modal">
            <div className="modal-content">
                {/*MAPA */
               
                  renderMap()
                   
                }
               

            </div>
            <div className="modal-footer">
                <Link to='' className="modal-close btn-flat">Aceptar</Link>
            </div>
         </div>
    )
}
