

import React from 'react'
import { GruposForm } from '../components/Users/GruposForm'
import '../css/Grupos.css';

export const GruposPage = () => {
  return (
    <GruposForm/>
  )
}
