import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
//import { objEvidences } from './exampleEvidenceObj';
import Swal from 'sweetalert2';
import { MapPopup } from '../MapPopup';
import M from 'materialize-css';
import { AuthContext } from '../../auth/authContext';
import { createOrGetConversation, sendGroupMessageByConversations } from '../../helpers/chatFunc';
import { getIncidence } from '../../helpers/incidenceFunc';
import { CircleGif } from '../CircleGif';

export const Incidencias = () => {

    const itemsPerPAge = 8;
    let count = 0;
    
    //Global info user dispatch
    const {currentUser} = useContext(AuthContext);
    const [listIncidence,setListIncidence] = useState([]);
    const [pageCount,setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");
    const [locations,setLocations] = useState({
        latitude:undefined,
        longitude:undefined
    });
    const [stateDataToMap, setDataToMap] = useState({
        
    });
    const [chatState,setChatStat] = useState('');
 
    //const getListIncidence = objEvidences; // ciclica onsanpshot

    useEffect(() => {
       let isMounted = true;
        let modalM = document.querySelectorAll('.modal');
        M.Modal.init(modalM);
        return () => {
            isMounted = false;
            };
        }, []);
    
        //list of Incidences
        const [listIncidenceState,setListIncidences] = useState({
            listIncidenceData:[],
            loading:true,
        });

        useEffect(()=>{
            let isMounted = true;
            getIncidence(serachText,currentUser.idCompany,currentUser.id,currentUser.typeUser).then((result)=>{
                //console.log(result);
                setListIncidences({
                    listIncidenceData:result,
                    loading:false,
                })
            })
            return () => {
                isMounted = false;
                };
        },[page]);

        const {listIncidenceData,loading} = listIncidenceState;
        const getListIncidence =listIncidenceData;
  
    
    const onChangeSerachTextInc = (e) => {
        setSerachText(e.target.value);
    }



     /**
     * GET List of Incidences
     */
    const retriveIncidences = ()=>{
        //const endOffset = (page) + itemsPerPAge;
        const items = getListIncidence.slice((page-1)*itemsPerPAge,(page-1)*itemsPerPAge+itemsPerPAge);
        setListIncidence(items);
        setPageCount(Math.ceil(getListIncidence.length/itemsPerPAge));
    }

    
        
   /**
    * update list each click or page change
    */
    useEffect(() => {
        let isMounted = true;

        retriveIncidences();
        return () => {
            isMounted = false;
            };
       
    }, [page,itemsPerPAge,listIncidenceState]);


        //serachForm
        const searchIncidence = (event) =>{

            event.preventDefault();
    
            setListIncidences({
                listIncidenceData:[],
                loading:true,
            });
    
            getIncidence(serachText,currentUser.idCompany).then((result)=>{
                console.log(result);
                setListIncidences({
                    listIncidenceData:result,
                    loading:false,
                })
            })
    
            if(listIncidenceState.listIncidenceData.length > 0){
                retriveIncidences();
            }
    
            
    
        }

           //showPopMessage
    const showPopupMessage = (chatID)=>{
        Swal.fire({
        input: 'textarea',
        //inputLabel: 'Message',
        title:'Enviar Mensaje',
        //text:name,
        inputPlaceholder: 'Escriba su mensaje aquí...',
        inputAttributes: {
          'aria-label': 'Escriba su mensaje aquí',
          
        },
        
        showCancelButton: true,
        confirmButtonText:'Enviar',
        cancelButtonText:'Cancelar',
       }).then(async(result)=>{
        if(result.dismiss === 'cancel'){
          setChatStat('');
        }
        else if(result.dismiss === 'backdrop')
        {
            setChatStat('');
        }
        else{
            if(result.value !== ""){
                
                let listChatOnlyOne = [];
                listChatOnlyOne.push(chatID);
                //console.log('good',result.value);
                //const {selectedChats} = selectChat;
                //console.log('sendMessage to CHAT=>',listChatOnlyOne);
                await sendGroupMessageByConversations(listChatOnlyOne,currentUser.id,result.value);
                 setChatStat('');
                Swal.fire({
                    icon:'success',
                    title:'Entrega exitosa',
                    text:'El mensaje fue enviado con exito',
                });
                //funcion para enviar mensajes por n conversaciones
    
           }
    
           if(result.value === ""){
                Swal.fire({
                    icon:'warning',
                    title:'Aviso',
                    text:'El mensaje no puede ir vacio.'
                });
               
            }
        }
           
       });
    }

    //get Location
    const handlerLocation = (e) =>{
        e.preventDefault();
       // console.log(e.target.id);
        let getLocations = e.target.id.split('|');
        setLocations({
            latitude:parseFloat(getLocations[0]),
            longitude:parseFloat(getLocations[1])
        });
        setDataToMap({
            user_name:getLocations[2],
        vehicleNumber:getLocations[3],
        distance:parseFloat(getLocations[4]),
        dateString:getLocations[5]
        });
        //setLatLng(e.target.id);
    }

    //getConversationID
    const handlerCreateOrGetChat = (e) =>{
        e.preventDefault();
        let recipentID = e.target.id;
        //console.log(recipentID);
       createOrGetConversation(currentUser.id,recipentID,currentUser.idCompany).then((res)=>{
            console.log(res);
           setChatStat(res);
           if(res !== ''){
            showPopupMessage(res);
           }else{
               //erro pop
           }
        })
    }

    

    const handlePageChange = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        setPage(currentPage);
      };

    const handlerBackArrowPage= () => {
        const arrowBack = document.getElementById('backli');
            if(page===1){
                arrowBack.classList.add('disabled');
                setPage(1);
            }
            if(page > 1){
                arrowBack.classList.remove('disabled');
                const backPage = page  - 1;
                setPage(backPage);
            }

    }

    const handlerNextArrowPage=()=>{
        const netxArrow = document.getElementById('nextli');
        if(page===pageCount){
            netxArrow.classList.add('disabled');
            //setPage(pageCount);
        }
        if(page < pageCount){
            netxArrow.classList.remove('disabled');
            const nextPage = page  + 1;
            setPage(nextPage);
        }
    }


    console.log('List INCIDENTES ',listIncidence);
    //console.log(pageCount);

    const renderPagination = () =>{
        let listPageCount= [];
        for (let index = 0; index < pageCount; index++) {
         
            if(index == 0){
                let num = 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`}  key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)
                
            }else{
                let num = index + 1;
               listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)
               
            }
            
        }

        return listPageCount;
    }

    


       //renderTable Information 
       const listTableIncidence  =() =>{
        if(listIncidence.length > 0){
            return (
                <div className='contentTable'>
                <table className='striped highlight  responsive-table centered tableFont'>
                <thead className='borderbotomLine'>
                    <tr>
                    <th className='center'>Fecha</th>
                    <th className='center'>Operador</th>
                    <th className='center'>Origen</th>
                    <th className='center'>Destino</th>
                  
                    <th className='center'>Observación</th>
                    <th className='center'>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                         
                         listIncidence.map((incidence)=>{
                        count++;
                        return <tr className={incidence.visto === 0 ? 'newsEvidences' :''}
                        id={count % 2 === 0 ? 'rowHighLigth' : ''}
                        key={`${incidence.dateString}-${count}`}>
                            <td>{incidence.dateString}</td>
                            <td>{incidence.userName}</td>
                           
                            <td>{incidence.origin}</td>
                            <td>{incidence.destination}</td>
                          
                          
                            <td>{incidence.observation !== "" ? incidence.observation.substring(0,16) : 'NA'}</td>
                            <td>
                                <div className='optionsEvi'>
                                <Link to='' id={incidence.userUid} onClick={handlerCreateOrGetChat}><i className='material-icons iconsList' id={incidence.userUid} style={{pointerEvents:'none'}}>question_answer</i></Link>
                                <Link to={`/incidencias/${incidence.id}`} id={incidence.id}><i className='material-icons iconsList' id={incidence.id}>collections</i></Link>
                                <Link to="" data-target="modal1" className=' modal-trigger' id={`${incidence.statusLat}|${incidence.statusLon}|${incidence.userName}|${incidence.vehicleNumber}|${incidence.distanceRemain}|${incidence.dateString}`}
                                    onClick={handlerLocation}>
                                    <i className='material-icons iconsList' style={{pointerEvents:'none'}}>pin_drop</i>
                                </Link>
                                </div>
                   
                            </td>
                            </tr>
                    })
                    }
                </tbody>
            </table>
            <div className='row center'>
                <ul className='pagination'>
                    <li className={`${page===1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                        {renderPagination()}
                    <li className={`${page===pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                </ul>
            </div>
        </div>
            )
        }else{
            //
        }
    }

    return (
        <>
        <div className='containerEvid'>
                
                <div className="" style={{background:'#5E5E68'}}>
                    <form className='center' style={{padding:'10px 0px'}}  onSubmit={searchIncidence}>
                        <div className="input-field inputSerach ">
                            <i className="material-icons  prefix " style={{color:'#A3A3A9', left:'0px', top:'10px'}}>search</i>
                            <input id="search" type="text"  placeholder='Buscar'  className='inputSerachBorderBottom' value={serachText} onChange={onChangeSerachTextInc}/>
                            
                        </div>
                    </form>
                </div>

                <div className='row center'><h4>INCIDENCIAS</h4></div>

                { loading ===true ? <div className='row center'><CircleGif /></div> : listTableIncidence() }
           
        </div>
        <MapPopup latitude={locations.latitude} longitude={locations.longitude} infoData={stateDataToMap}/>
        </>
    )
}



