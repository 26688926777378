

import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
//import { useState } from 'react/cjs/react.development';
import Swal from 'sweetalert2';
import { AuthContext } from '../../auth/authContext';
import { sendEmail } from '../../helpers/evidenceFunc';
import { useGetimgEvidence } from '../../hooks/hooks_evidence'
import { CircleGif } from '../CircleGif';
//import { DialogValidation } from '../DialogValidation';

export const EvidenciaInfo = () => {
    
      //Global info user dispatch
    
      const {currentUser} = useContext(AuthContext);
    const {evidenceid} = useParams();
   //const [listImgSelect,setListImgSelect] = useState([]);
    //console.log("ServiceDetailID => ",evidenceid);
    const {listImgEvidence,loadingImg} = useGetimgEvidence(evidenceid,currentUser.idCompany)
    
    //const {listImgEvidence,loadingImg} = stateImgEvidences;
    //console.log("Images => ",listImgEvidence);
    console.log("cargando => ",loadingImg);

    //validar campos y lista de imagenes selecionadas
    const handlerValidation = async(e) =>{
        e.preventDefault();
        console.log('Click enviar');
        let msgError ='';
        const emial = document.getElementById('email');
        const subject = document.getElementById('subject');
        const message = document.getElementById('txtmessgage');
        //selecPictures
       let listImg = handlerSelectPic();
        if(emial.value === "" && subject.value === "" && listImg.length === 0){
            msgError += 'El correo, Asunto son campos requeridos, así como al menos debes selecciónar una imagen.\n'
            //sweatAlert(msgError);
        }

        if(emial.value !== "" && subject.value !== "" && listImg.length === 0){
            msgError += 'Al menos debes selecciónar una imagen.\n'
            //sweatAlert(msgError);
        }

        if(emial.value === "" && subject.value === "" && listImg.length > 0){
            msgError += 'El correo, Asunto son campos requeridos, por favor introduce valores.\n'
            //sweatAlert(msgError);
        }

        if(emial.value !== "" && subject.value === ""){
            msgError += 'El Asunto es requerido.\n'
            //sweatAlert(msgError);
        }
        //validar correo valido
        //if(){}
        if(emial.value === "" && subject.value !== ""){
            msgError += 'El correo correo es requerido.\n'
            
        }

        if(msgError !== ""){
            sweatAlert(msgError);
        }else{
            //funcion para enviar correo
           await sendEmailPic(emial.value,subject.value,message.value,listImg);
        }

    }

    //funcion para enviar correo con imagenes.
    const sendEmailPic = async(correo,subj,msg,listPictures) =>{ 

        const objData ={
            senderEmail :currentUser.email,
            whoRecibe : correo,
            subject: subj,
            msg:msg,
            idCompany:currentUser.idCompany
        };
        
        await sendEmail(listPictures,objData);

    }

    //funcion para seleccionar dichas imagenes.
    const handlerSelectPic = () =>{
        //e.preventDefault();
        let listPictures = [];
       // setListImgSelect([]);
        const getAllchecboxSelected = document.querySelectorAll('.ck1');
        for(let x = 0; x < getAllchecboxSelected.length; x++){
               
            if(getAllchecboxSelected[x].checked === true){
                const urlPic = getAllchecboxSelected[x].value;
                console.log(urlPic);
                listPictures.push(urlPic);
            }
            
        }
        if(listPictures.length > 0){
            //setListImgSelect(listPictures);
        }else{
            //setListImgSelect([]);
        }
        console.log(listPictures.length);
        return listPictures;
    }



    const renderListImg = () =>{
        let viewImgList = [];
        if(listImgEvidence.length === 1){
            listImgEvidence.forEach((e,index)=>{
                const img = e.imageURL;
                viewImgList.push(
                    <tr key={index}>
                        <td >
                            
                            <img src={img} width={250} height={300} className='responsive-img'/>
                            <input className='ck1 filled-in' type="checkbox" value={`index`}/>
                        </td>
                   
                </tr>
                );
            })
        }
        if(listImgEvidence.length > 1){
            const size = (listImgEvidence.length - 1);
            for (let index = 0; index < listImgEvidence.length; index++) {
                    const currentUrl = index;
                    const nextUrl = index + 1;

                    if(index === size){
                        const curImg = listImgEvidence[index];
                        viewImgList.push(
                            <tr key={index}>
                                <td >
                                    
                                    <img src={curImg.imageURL} width={250} height={300} className=' sizetallImg responsive-img'/>
                                    <input className='ck1 filled-in' type="checkbox" value={curImg.imageURL}/>
                                </td>
                                <td >
                                  
                                </td>
                           
                        </tr>
                        );
                    }
                    

                if(index < size){
                    
                  if(nextUrl <= size){
                    const curImg = listImgEvidence[currentUrl];
                    const nextImg = listImgEvidence[nextUrl];
                    viewImgList.push(
                        <tr key={index}>
                            <td >
                                
                                <img src={curImg.imageURL} width={250} height={300} className=' sizetallImg responsive-img'/>
                                <input className='ck1 filled-in' type="checkbox" value={curImg.imageURL}/>
                            </td>
                            <td >
                                
                                <img src={nextImg.imageURL} width={250} height={300} className=' sizetallImg responsive-img'/>
                                <input className='ck1 filled-in' type="checkbox" value={nextImg.imageURL}/>
                            </td>
                       
                    </tr>
                    );

                     //
                    /*if(size % 2 == 0){
                        index = nextUrl ;
                    }else{
                        
                    }*/
                    index = nextUrl;

                  }
                                
                }
               


            

               
            
            }
        }
        if(listImgEvidence.length == 0){
            viewImgList = [];
        }
        return viewImgList;
    }

    return (
        <div className='fondInfo'>
        <div className=' row containerIfo'>
            <div className='contentInfo'>
                <div className='colorone'>
                    <form>
                        
                            <div className='input-field col s11 globalFormEviInc'>
                                <input placeholder="Correo" id="email" type="email" className="" name='email' />
                            </div>
                            <div className='input-field col s11 globalFormEviInc'>
                                <input placeholder="Asunto" id="subject" type="text" className="" name='subject' />
                            </div>
                            <div className='input-field col s11 inputMessage-evidence'>
                         
                                <textarea className='txtAreaBorder-None-eviden'  type='text' name='txtmessgage' id='txtmessgage' placeholder='Mensaje' style={{color:'#75747D'}}></textarea>
                      
                            </div>

                            <div className=''>
                           
                                

                                <button id='sendEmail' className='btn btnFromLogin' type='button' onClick={handlerValidation}>Enviar</button>
                                <Link to='/evidencias' className='btn btnFromLogin'  id='backP' style={{background:'#5E5E68'}}>Regresar</Link>

                            </div>
                            
                      
                    </form>
                </div>
                <div className='colortwo'>
                {loadingImg == true ? <div className='row center'><CircleGif /></div> :
                    <div className='contenGrid'>
                        
                    

                    <table className='centered responsive-table'>
                        <thead>
                          
                                {listImgEvidence.length > 1 ? 
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr> :
                                <tr>
                                <th></th>
                                </tr> 
                            }
                                
                            
                        </thead>
                        <tbody >
                            {/**render list of images */}
                            {renderListImg()}
                           
                        </tbody>
                    </table>
                   

                    </div>
                    //end condition loading
                }
                </div>
            </div>
            
        </div>
        </div>
    )
}



const sweatAlert =(messageErr) =>{
    
    Swal.fire({
        title:'Aviso',
        text: messageErr !== '' || messageErr !== null ? messageErr : 'NA',
        icon:'warning'
     });
}
