

import React from 'react'
import { Incidencias } from '../components/Incidences/Incidencias'

export const IncidenciasPage = () => {
    return (
        <Incidencias />
    )
}
