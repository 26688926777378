import Swal from 'sweetalert2';
import firebase from '../config/global-variables';



const getIncidence = async(serachText,idCompany,uid,type)=> {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getIncidents`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({field:0,value: serachText,companyID:idCompany,typeUser:type,managerID:uid}),
            //mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        console.log('INCIDENCIAS FROM SERVER =>',Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop();
        return [];
        //swal alert to return to home
    }
}


const getCurrentIncidence = async(id,idCompany)=> {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getIncidents`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({field:10,value: `${id}`,companyID:idCompany}),
            //mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        console.log('currentIncidence =>',Data);
        return Data;
    } catch (error) {
        console.log(error)
        swalMessagepop();
        return [];
        //swal alert to return to home
    }
}



const swalMessagepop = () => {
    Swal.fire({
        title:'Advertencia',
        text:'No se pudo obtener la información, intentelo mas tarde, problema de conexíon a la base de datos.',
        icon:'error',
        confirmButtonText:'Ok',
    });
}

export{
    getIncidence,
    getCurrentIncidence,

}