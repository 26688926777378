

import React, { useContext, useEffect } from 'react'
import {  Link, NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router';
import M from 'materialize-css';
import { logOut } from '../../config/ConfigFirebase'
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/type';


export const Navbar = () => {
    
    //get info del useContext user information
    const navigate = useNavigate();
    const {currentUser,dispatch} = useContext(AuthContext);
     
    useEffect(() => {
        let dropnav = document.querySelectorAll('.dropdown-trigger');
        var elems = document.querySelectorAll('.sidenav');
        let zoomImgs = document.querySelectorAll('.materialboxed');
       

        
        M.Materialbox.init(zoomImgs);
        M.Dropdown.init(dropnav);
        M.Sidenav.init(elems);
        
        
     
       
     }, []);
    
    //LOGOUT
    const heandlerLogOut = async()=>{
        //POR HACER
      console.log('logout')

        await logOut();

       dispatch({
        type: types.logout
    });
        
    navigate('/login',{
        replace:true,

    })
    
    }
    
    return (
    <>
  
    <nav style={{height:'200px'}} className='imgNav'>
        <div className="nav-wrapper navSizeDiv" style={{top:'33%'}}>
            <NavLink
            className='brand-logo'
            to='/'
            >
                <div className='logoNav'></div>
               
            </NavLink>
            <NavLink to='#' data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></NavLink>
            <ul id="nav-mobile" className="right hide-on-med-and-down margin-right">
                <li><NavLink to='chat' id='chat'><span>Chat</span></NavLink></li>
                <li><NavLink to='mapa' id='mapa'>Mapa</NavLink></li>
                <li><NavLink to='evidencias' id='evi'>Evidencias</NavLink></li>
                <li><NavLink to='incidencias' id='inc'>Incidencias</NavLink></li>
                { currentUser.typeUser === 2 ? <li><NavLink to='usuarios' id='usuarios'>Usuarios</NavLink></li> : <li></li>}
                <li><NavLink to='#' className='dropdown-trigger' data-target='dropdown1'><button className='btn-floating btn-medium'><img alt={currentUser.name} src={currentUser.image != '' ? currentUser.image : `/assets/marvel-thor.jpg`} className='circle responsive-img'/></button></NavLink>
                    <ul id="dropdown1" className="dropdown-content show-ps">
                        <li><Link to='changePassword'><span className='title '>Cambiar contraseña</span></Link></li>
                        <li className="divider"></li>
                        <li><Link to='perfil'><span className='title '>Editar Perfil</span></Link></li>
                        <li className="divider"></li>
                        <li><Link to='' onClick={heandlerLogOut}><span className='title'>Cerrar Sesión</span></Link></li>
                    </ul>
                </li>
            </ul>
          
            
        </div>

       
  </nav>

{/*SIDE NAV MObile & tablet */}
  <ul id="mobile-demo" className="sidenav">
    <li><div className="user-view userViewM">
      <div className="background">
        <img src="images/office.jpg"/>
      </div>
      <Link to='' ><img alt={currentUser.name} src={currentUser.image != '' ? currentUser.image : `/assets/marvel-thor.jpg`} className='circle responsive-img'/></Link>
      <span className="white-text name">{currentUser.name}</span>
     <span className="white-text email">{currentUser.email}</span>
    </div></li>
                <li><NavLink to='chat' id='chat'><i className='material-icons '>question_answer</i><span>Chat</span></NavLink></li>
                <li className="divider"></li>
                <li><NavLink to='mapa' id='mapa'><i className='material-icons '>map</i>Mapa</NavLink></li>
                <li className="divider"></li>
                <li><NavLink to='evidencias' id='evi'><i className='material-icons '>assignment</i>Evidencias</NavLink></li>
                <li className="divider"></li>
                <li><NavLink to='incidencias' id='inc'><i className='material-icons '>collections</i>Incidencias</NavLink></li>
                <li className="divider"></li>
                {currentUser.typeUser === 2  ? <li><NavLink to='usuarios' id='usuarios'><i className='material-icons '>people</i>Usuarios</NavLink></li> : <li></li>}
                <li className="divider"></li>
                <li className="divider"></li>
                <li><Link to='changePassword'><i className='material-icons '>lock</i>Cambiar contraseña</Link></li>
                    <li className="divider"></li>
                <li><Link to='perfil'><i className='material-icons '>person</i>Editar Perfil</Link></li>
                    <li className="divider"></li>
                <li><Link to='' onClick={heandlerLogOut}><i className='material-icons '>logout</i>Cerrar Sesión</Link></li>
  </ul>
  {/**END SIDE NAV */}
  </>
    )
}


