/**
 * Home functions conunters.
 */


 import Swal from 'sweetalert2';
import { myFirestore} from '../config/ConfigFirebase';
 import firebase from '../config/global-variables';

 /**
  * GET TOTAL MESSAGES UNREADED
  */

 const getAllMsgUnread = async(currenUserId) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getMessageByConversationUnread/${currenUserId}`;
        const headers = {"Content-Type":"application/json"};
        const response = await fetch(url);
        
       const data = await response.json();
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;
      
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la cantidad de mensajes nuevos, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return {};
    }
 }

 /**
  * GET NEW EVIDENCES 
  */

  const getAllEvidences = async(userCompany) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountEvidences`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia' + error,
            text:'No se pudo obtener la cantidad de evidencias nuevas, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }

  /**
  * GET NEW INCIDENCIAS 
  */

   const getAllIncidencias = async(userCompany) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountIncidents`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia' + error,
            text:'No se pudo obtener la cantidad de inicidencias nuevas, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }


 /**
  * GET ALL USERS 
  */

  const getAllUsers = async(userCompany) =>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getCountUsers`;
       
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({companyID: userCompany}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();
        
       //console.log(data);
       //const parseData = JSON.parse(data)
       // const count = parseData;   
        return data;
    } catch (error) {
        console.error(error);
        
        Swal.fire({
            title:'Advertencia' + error,
            text:'No se pudo obtener el total de usuarios, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return 0;
    }
 }

 export {getAllMsgUnread,getAllEvidences,getAllIncidencias,getAllUsers}