import React from 'react';
import ReactDOM from 'react-dom';


import { Root } from "../src/Routes/Root";


import './css/index.css';



//serviceWorker registration
if ('serviceWorker' in navigator) {
       
    navigator.serviceWorker.getRegistrations().then((registrations)=>{
      console.log("Registrations =>",registrations);
      if(registrations.length === 0){
        /*getToken(messagingNTF,{vapidKey:keyMessaging}).then((currentToken)=>{
          console.log('TOKEN=>',currentToken);
        }).catch((err)=>{
          console.log('ERR=>,',err);
        })*/
        navigator.serviceWorker.register(process.env.PUBLIC_URL + "/firebase-messaging-sw.js").then((registration)=>{
          console.log("Registration successful, scope is:", registration);

         }).catch((err)=>{
          console.log("Service worker registration failed, error:", err);
         });
      }
      if(registrations.length > 0){
        console.log("Ya estas registrado en firebase-messagin-sw.js")
      }
    })
     
 }

 

ReactDOM.render(<React.StrictMode><Root/></React.StrictMode>,document.getElementById('root'));