
import {  signInEmailAndPassword , myFirestore, messaging, myFirebase} from "../config/ConfigFirebase"
import { getDoc,doc,updateDoc } from "firebase/firestore";
import {getToken, getMessaging} from 'firebase/messaging';
import firebase  from "../config/global-variables";
import Swal from "sweetalert2";

//{ keyMessaging, keyPairCloudMessageWeb }

//LOGIN INTO SYSTEM update login and create new data.
const sinIn = async(email, password)=>{

  try {
    let uid = await  signInEmailAndPassword(email,password);
    console.log('Response => ', uid);
    if(uid !== undefined ){
            const userData = await getCurrentUser(uid);
    //console.log('Current-user: '+userData.email);
            const currentUser = {...userData,logged:true};
        if(!localStorage.getItem('currentUser')){
            localStorage.setItem('currentUser',JSON.stringify(currentUser));
        }else{
            localStorage.removeItem('currentUser');
            localStorage.setItem('currentUser',JSON.stringify(currentUser));
        }
          return currentUser;
    }
    if(uid === undefined){
      const currentUser = {logged:false};
      Swal.fire({
        title:'Advertencia',
        text:'Datos incorrectos verifique por favor los datos introducidos como, el email y password.',
        icon:'warning',
        confirmButtonText:'Ok',
    });
      return currentUser;
    }
  } catch (error) {

    
    Swal.fire({
      title:'Error' + error,
      text:'No se pudo conectar al servidor de Firebase /o el usuario no existe en el sistema.',
      icon:'error',
      confirmButtonText:'Ok',
  });
    return {logged:false};
    
  }

     
}

//GETINFOUSER
const getCurrentUser = async(uid) => {
    const request = doc(myFirestore,'Users',uid);
    //query(collection(myFirestore,'Users'),doc(uid));
    const response = await getDoc(request);
    let userData;
    if (response.exists()) {
      
        userData = {
          id: response.id,
          name:response.data()['name'],
          email:response.data()['email'],
          token:response.data()['token'],
          userType:response.data()['userType'],
          image:response.data()['image'],
          idCompany:response.data()['idCompany'],
          status:response.data()['status'],
          typeUser:response.data()['TypeUser']
        }

        await updateTokenFielUser(uid)
        
    } else {
      // doc.data() will be undefined in this case
      userData={};
      console.log("No such document!");
      Swal.fire({
        title:'Error',
        text:'El usuario que desea acceder, no existe en el sistema.',
        icon:'error',
        confirmButtonText:'Ok',
    });
    }
    
    return userData;
}

const updateTokenFielUser = async(uid)=>{
    try {
      const request = doc(myFirestore,'Users',uid);
      const messagingNTF = getMessaging(myFirebase);
      //get permmistions
      await Notification.requestPermission().then(async permission => {
        if (permission === "denied") {
          console.log("Permission wasn't granted. Allow a retry.");
          return;
      } else if (permission === "default") {
          console.log("The permission request was dismissed.");
          return;
      }


      })
  
     const currentToken = await getToken(messagingNTF,{vapidKey:firebase.keyPairCloudMessageWeb});
     console.log('TOKEN ==>',currentToken);
      /*if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/node_modules/firebase/firebase-messaging-sw.js', {scope: '/'});
      }*/
      //const token = 
       
      //console.log('token',token);
     const date = Date.now();
      const format = new Date(date);
     await updateDoc(request,{"lastSeen":format,"tokenWeb":currentToken});
      
    } catch (error) {
      console.log(error);
    }
}

export {sinIn, getCurrentUser,updateTokenFielUser};