import React from 'react'

export const ImagePop = () => {

    const handlerClosePic = (e) =>{
        e.preventDefault();
        const span = document.getElementsByClassName("close")[0];
        const modal = document.getElementById("myModalPic");
        modal.style.display='none';
    }
    return (
        <div id="myModalPic" className="modalPic">
            <span className="close" onClick={handlerClosePic}>&times;</span>
                <img className="modal-contentPic" id="img01"/>
                <div id="caption"></div>
        </div>
    )
}
