import Swal from 'sweetalert2';
import firebase from '../config/global-variables';


//GET EVIDENCES
const getEvidences = async(serachText,idCompany,uid,type)=> {
    try {
        //getEvidences
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getHeadEvidences`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({field:0,value: serachText,companyID:idCompany,typeUser:type,managerID:uid}),
            //mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        //console.log('DataEvidences =>',Data);
        return Data;
    } catch (error) {
        console.log(error);
        console.log('DataEvidences =>',[]);
        swalMessagepop();
       return [];
    }
}

//GET IMGS
const getImgEvidence = async(id,idCompany)=>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getEvidences`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({serviceDetailID:id,companyID:idCompany}),
            //mode:'no-cors'
        };
        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;

        console.log('ImageEvidences =>',Data);
        return Data;
    } catch (error) {
        console.log(error);
        //console.log('ImageEvidences =>',[]);
        swalMessagepop();
        return [];
    }
}

const swalMessagepop = (message='') => {
    Swal.fire({
        title:'Advertencia',
        text: message === '' ? 'No se pudo obtener la información, intentelo mas tarde, problema de conexíon a la base de datos.':message,
        icon:'warning',
        confirmButtonText:'Ok',
    });
}


//send Email
/**
 * 
 *  listURLIMg => lista de las url de imagenes
 *  objData => object de informacion {
 * senderEmail,companySender,whoRecibe,subject,msg
 * } 
 */
const sendEmail = async(listURLIMg,objData) =>{

    try {
        Swal.fire({
            title:'Enviando Correo.',
            allowOutsideClick:false,
            allowEscapeKey: false,
            html:'<b>Por favor espere...</b>',
            didOpen: () => {
                Swal.showLoading()
            },
        })
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/sendEmail`;
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({
                listImg:listURLIMg,
                senderEmail:"smtp.gmail.com", //objData.senderEmail,
                reciber:objData.whoRecibe,
                subject:objData.subject,
                message:objData.msg,
                idCompany:objData.idCompany,
            }),
           // mode:'no-cors'
        };
        const response = 
        await fetch(url,optionsRequest);
        console.log(response);
        if(response.status === 200 && response.ok === true){
            Swal.close();
            Swal.fire({
                title:'Entrega',
                text:'La entrega de tu correo fue exitosa',
                 icon:'success',
            })
        }else{
            Swal.close();
            Swal.fire({
                title:'Error',
                text:'La entrega de tu correo no pudo ser enviada, intente mas tarde problema, referido con el servidor o configuración',
                 icon:'error',
            })
        }
       // const data = await response.json();
      // console.log('sendEmail => ',data);
      /* Swal.fire({
           title:'Entrega',
           text:'La entrega de tu correo fue exitosa'+ response,
            icon:'success',
       });*/
        
    } catch (error) {
        Swal.close();
        //console.log(error);
        //console.log('ImageEvidences =>',[]);
        swalMessagepop('Error al enviar correo, se perdio la conexión al servidor o mas bien fallo la entrega.' + error);
        
    }

}

export{
    getEvidences,
    getImgEvidence,
    sendEmail
}