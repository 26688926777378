import {auth, db, myFirebase, myFirestore, myStorage} from '../config/ConfigFirebase';
import firebaseConfig from '../config/ConfigFirebase';
import { collection, query, where, getDocs,orderBy, doc, collectionGroup, DocumentReference, Firestore, addDoc, Timestamp } from "firebase/firestore";
import { nameProyectFirebase } from '../config/global-variables';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { AuthContext } from '../auth/authContext';
import { now } from 'moment';

const collectionUsers = 'Users';

const getUsers = async(companyID, searchText) => {
    let listUsers=[];

    try {
        // console.log("companyID=>" + companyID);
        if(searchText === ""){
            const response =  query(collection(myFirestore,collectionUsers),where('idCompany','==',companyID));
            const querySnapshot = await getDocs(response);
            
            querySnapshot.forEach((doc)=>{
                // console.log("=======>" + doc.data()['typeUserName']);
               // if (getUsers) {
                let user={
                    id:doc.id,
                    email:doc.data()['email'],
                    image: doc.data()['image'],
                    lastSeen:doc.data()['lastSeen'],
                    name:doc.data()['name'],
                    status:doc.data()['status'],
                }
                listUsers.push(user);
            //}
            });
        }

        if(searchText !== ""){
            const response =  query(collection(myFirestore,collectionUsers),where('idCompany','==',companyID));
            const querySnapshot = await getDocs(response);
            
            querySnapshot.forEach((doc)=>{
                // console.log("=======>" + doc.data()['typeUserName']);
                const nameUser = doc.data()['name'];
                if(nameUser.toUpperCase().includes(searchText.toUpperCase())){
                   // if (getUsers) {
                        let user={
                            id:doc.id,
                            email:doc.data()['email'],
                            image: doc.data()['image'],
                            lastSeen:doc.data()['lastSeen'],
                            name:doc.data()['name'],
                            status:doc.data()['status'],
                        }
                        listUsers.push(user);
                   // }
                }     
            });
        }
       
    }
    catch(error) {
        //console.log("Error=>");
        listUsers= [];
    }

    return listUsers;
  
}

const getUser = async(email) => {
    let listUser=[];
    // console.log("userIDXX=>" + userID);
    try {
        const response =  query(collection(myFirestore,collectionUsers),where('email','==',email));
        const querySnapshot = await getDocs(response);
        
        querySnapshot.forEach((doc)=>{
            // console.log("=======>" + doc.data()['typeUserName']);
            let user={
                id:doc.id,
                email:doc.data()['email'],
                image: doc.data()['image'],
                lastSeen:doc.data()['lastSeen'],
                name:doc.data()['name'],
                status:doc.data()['status'],
                typeUser: doc.data()['typeUser'],
                active: doc.data()['active'],
            }
            listUser.push(user);        
        });
    }
    catch(error) {
        console.log("Error=>" + error);
    }

    return listUser;
  
}

export {getUsers, getUser};