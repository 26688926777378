


import Swal from 'sweetalert2';
import firebase from '../config/global-variables';

const getPositions = async(userID)=> {
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getOperatorsLastPosition/${userID}`;
        const response = await fetch(url);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        console.log('Positions =>',Data);
        return Data;
    } catch (error) {

        
        Swal.fire({
            title:'Error',
            text:'No se pudo conectar al servidor de Firebase, intentelo mas tarde.',
            icon:'error',
            confirmButtonText:'Ok',
        });

        return [];
        
    }
}

export{getPositions}