

import React from 'react'


import { ChatBlankPage } from './ChatBlankPage';
import { ChatLivingRoom } from './ChatLivingRoom';

/*const currentChatData = () =>{
    return !localStorage.getItem('chatCurrent') ? {} : JSON.parse(localStorage.getItem('chatCurrent'));
}*/


export const ChatMain = (convID) => {
    
    //console.log('conversation',convID);
    /*const [dataChat,setDataChat] =  useState({ name:'',
    conversationID:'',
    idContact:'', 
    image:'',
    status:''});
    useEffect( ()=>{
        setDataChat({ name:conversation.name,
        conversationID:conversation.conversationID,
        idContact:conversation.idContact, 
        image:conversation.image,
        status:conversation.status})
    },[conversation.conversationID])*/

    return (
       
         
           <div className=''>
              {
               
               convID.convID === "" ? <ChatBlankPage /> : <ChatLivingRoom idConversation={convID.convID}/>
               
               }
           </div>
           
           
         
    )
}
