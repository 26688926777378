import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { MapPopup } from '../MapPopup';
import M from 'materialize-css';
import {getUsers} from '../../helpers/usersFunc';
import { CircleGif } from '../CircleGif';
import { createOrGetConversation, sendGroupMessageByConversations } from '../../helpers/chatFunc';
import { AuthContext } from '../../auth/authContext';

export const UsersHomeForm = () => {

    const itemsPerPAge = 8;
    let count = 0;
    
     //Global info user dispatch
     const {currentUser} = useContext(AuthContext);
    
    const [listUser,setListUser] = useState([]);
    // const [stateLatLng,setLatLng] = useState('');
    const [pageCount,setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");
    /*
    const [locations,setLocations] = useState({
        latitude:undefined,
        longitude:undefined
    });
    const [stateDataToMap, setDataToMap] = useState({
        
    });
    */

    
    useEffect(() => {

        let isMounted = true;
     let modalM = document.querySelectorAll('.modal');
     M.Modal.init(modalM);
     return () => {
        isMounted = false;
        };
     }, []);

     //list of users
    const [listUserState,setListUsers] = useState({
        listUserData:[],
        loading:true,
    });
    useEffect(()=>{
        let isMounted = true;
        getUsers(currentUser.idCompany, serachText).then((result)=>{
            console.log(result);
            setListUsers({
                listUserData:result,
                loading:false,
            })
        })
        return () => {
            isMounted = false;
            };
    },[page]);
    const {listUserData,loading} = listUserState;
    const getListUser =listUserData;


    
    const onChangeSerachTextUser = (e) => {
        //e.preventDefault();
        //const searchText = e.target.value;
        setSerachText(e.target.value);
    }


     /**
     * GET List of users
     */
    const retriveUsers = ()=>{
        //const endOffset = (page) + itemsPerPAge;
        const items = getListUser.slice((page-1)*itemsPerPAge,(page-1)*itemsPerPAge+itemsPerPAge);
        console.log(items);
        setListUser(items);
        setPageCount(Math.ceil(getListUser.length/itemsPerPAge));
    }
   /**
    * update list each click or page change
    */
    useEffect(() => {
        let isMounted = true;
        retriveUsers();
        return () => {
            isMounted = false;
            };
    }, [page,itemsPerPAge,listUserState]);

    //serachForm
    const searchUser = (event) =>{

        event.preventDefault();

        setListUsers({
            listUserData:[],
            loading:true,
        });

        getUsers(currentUser.idCompany, serachText).then((result)=>{
            console.log(result);
            setListUsers({
                listUserData:result,
                loading:false,
            })
        })

        if(listUserState.listUserData.length > 0){
            retriveUsers();
        }

        

    }

  

    
    //page change btn
    const handlePageChange = (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        setPage(currentPage);
      };


      //backArrow
    const handlerBackArrowPage= () => {
        const arrowBack = document.getElementById('backli');
            if(page===1){
                arrowBack.classList.add('disabled');
                setPage(1);
            }
            if(page > 1){
                arrowBack.classList.remove('disabled');
                const backPage = page  - 1;
                setPage(backPage);
            }

    }

    //Next Arrow
    const handlerNextArrowPage=()=>{
        const netxArrow = document.getElementById('nextli');
        if(page===pageCount){
            netxArrow.classList.add('disabled');
            //setPage(pageCount);
        }
        if(page < pageCount){
            netxArrow.classList.remove('disabled');
            const nextPage = page  + 1;
            setPage(nextPage);
        }
    }


    
    
   

    //RENDER PAGINATION
    const renderPagination = () =>{
        let listPageCount= [];
        for (let index = 0; index < pageCount; index++) {
         
            if(index == 0){
                let num = 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`}  key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)
                
            }else{
                let num = index + 1;
               listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)
               
            }
            
        }

        return listPageCount;
    }


    //renderTable Information 
    const listTableUser  =() =>{
        if(listUser.length > 0){
            return (
                <div className='contentTable'>
                    <div className='contentBtnUsers'>
                    <NavLink to="/usuarios/register"  className='btn waves-effect waves-light btnFromRegisterCancel' style={{marginRight:'10px'}}>
                    Registrar
                    </NavLink>

                    <Link to='/usuarios/grupos' className='btn waves-effect waves-light btnFromRegisterCancel'>Grupos</Link>
                    </div>
               
                <table className='striped highlight  responsive-table centered tableFont'>
                <thead className='borderbotomLine'>
                    <tr>
                    <th className='center'>Nombre</th>
                    <th className='center'>Email</th>
                    <th className='center'>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    {
                         
                        listUser.map((user)=>{
                        count++;
                        return <tr 
                                id={count % 2 === 0 ? 'rowHighLigth' : ''}
                                key={`${count}`}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.status}</td>
                                    <td>
                                        <div className='optionsEvi'>
                                            <Link to={`/usuarios/userEdit/${user.email}`} id={user.id}>
                                                <i className='material-icons iconsInputs' email={user.id}>edit</i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                        })
                    }
                </tbody>
            </table>
            <div className='row center'>
                <ul className='pagination'>
                    <li className={`${page===1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                        {renderPagination()}
                    <li className={`${page===pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                </ul>
            </div>
        </div>
            )
        }else{}
    }



    return (
        <>
        <div className='containerUser'>
                
                <div className="" style={{background:'#5E5E68'}}>
                    <form className='center' style={{padding:'10px 0px'}} onSubmit={searchUser}>
                        <div className="input-field inputSerach ">
                            <i className="material-icons  prefix " style={{color:'#A3A3A9', left:'0px', top:'10px'}}>search</i>
                            <input id="search" type="text"  placeholder='Buscar'  className='inputSerachBorderBottom' value={serachText} onChange={onChangeSerachTextUser}/>
                           
                        </div>
                    </form>
                </div>

                <div className='row center'><h4>USUARIOS</h4></div>
                    
                    { loading ===true ? <div className='row center'><CircleGif /></div> : listTableUser() }
       
        </div>
                
        </> 
        

    )
}
