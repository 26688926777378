
import { myFirestore} from '../config/ConfigFirebase';
import { collection, query, where, getDocs,orderBy, doc,getDoc, onSnapshot, addDoc, updateDoc,arrayUnion } from "firebase/firestore";

import firebase from '../config/global-variables';
import Swal from 'sweetalert2';
import { connectStorageEmulator } from 'firebase/storage';
//{ firebase.nameProyectFirebase, keyMessaging, firebaseNotificationSend }






//GET CONTACTS BY USER
//aqui falta ver los parametros que resivira por ejemplo typeUser and idCompany
//parametro typeUser & and searchText
const collectionUser = 'Users';
const collectionConversation = 'Conversations';

const getContacts = async(idCompany,uid,searchText,typeUser) => {
    let listUsers=[];

  try {
    if(searchText == ""){
        const response =  query(collection(myFirestore,collectionUser),where('idCompany','==',idCompany),orderBy('name'));
        const querySnapshot = await getDocs(response);
        
        //console.log(querySnapshot.size);
        querySnapshot.forEach((doc)=>{
            let user={
                id:doc.id,
                name:doc.data()['name'],
                emial: doc.data()['email'],
                lastSeen:doc.data()['lastSeen'],
                serviceID: doc.data()['serviceID'],
                status: doc.data()['status'],
                token: doc.data()['token'],
                image: doc.data()['image'],
                userType: doc.data()['TypeUser'],
                managerID:doc.data()['managerID']
             }
                if(doc.id !== uid ){

                    if(typeUser === 2){
                        if(doc.data()['TypeUser'] === 3 ){
                            listUsers.push(user);
                        }
                    }
                    if(typeUser === 3){
                        if(doc.data()['TypeUser'] === 4 && doc.data()['managerID'] === uid){
                            listUsers.push(user);
                        }
                        if(doc.data()['idCompany'] === idCompany  && doc.data()['TypeUser'] === 2){
                            listUsers.push(user);
                        }
                    }
                     
                }
            
            });
        }
    
        if(searchText !== ""){
            //const toLowers = searchText.toLowerCase();
            //const makeFirst = searchText.charAt(0).toUpperCase().slide(1);
            const  newQuery = query(collection(myFirestore,collectionUser),where('idCompany','==',idCompany),orderBy('name'));
            //query(collection(myFirestore,collectionUser),where('idCompany','==',idCompany),where('name','==',searchText));
            const querySnapshotsSR = await getDocs(newQuery);
    
            querySnapshotsSR.forEach((newdoc)=>{
                const nameUser = newdoc.data()['name'];
                if(nameUser.toUpperCase().includes(searchText.toUpperCase())){
                    let user={
                        id:newdoc.id,
                        name:newdoc.data()['name'],
                        emial: newdoc.data()['email'],
                        lastSeen:newdoc.data()['lastSeen'],
                        serviceID: newdoc.data()['serviceID'],
                        status: newdoc.data()['status'],
                        token: newdoc.data()['token'],
                        image: newdoc.data()['image'],
                        userType: newdoc.data()['TypeUser'],
                        managerID:newdoc.data()['managerID']
                    }
                    if(newdoc.id !== uid){
                        
                    if(typeUser === 2){
                        if(newdoc.data()['TypeUser'] === 3 ){
                            listUsers.push(user);
                        }
                    }
                    if(typeUser === 3){
                        if(newdoc.data()['TypeUser'] === 4 && newdoc.data()['managerID'] === uid){
                            listUsers.push(user);
                        }
                    }
                        //listUsers.push(user);
                    }
                }
                
            })
        }
    
        /**
         * create another list of user empty where we save, the user with the type user
         * can chat each other.
         * example admin user only can chat with Monitoristas & Jefe Flotillas. 3
         * example Monitorista only can chat with Jefe FLotilla, & admin and Operadores. 4 and managerID 
         * example Jefe Flotilla only can chat with Monitoristas & admin and Operadores. 3 with managerID
         * 
         * make a forach and make a codition if my typeUser depends for the role. an return that list.
         */
        
    
       //console.log(listUsers);
        return listUsers;
  } catch (error) {
    Swal.fire({
        title: 'Problema de conexión.',
        text: 'No se puedo obtener la lista de contactos por algun problema de conexión al servicio de Firebase.',
        icon:'error',
    })
      return [];
  }
  
}


//GET CONVERSATIONS
const getConversations = async(uid,serachText)=>{
    //=================================
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getUserConversations/${uid}`;
      
        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({searchText: serachText}),
           // mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
      
        return Data;
        
    } catch (error) {
        console.error(error);
        Swal.fire({
            title: 'Problema de conexión.',
            text: 'No se puedo obtener la lista de conversaciónes, por algun problema de conexión al servicio de Firebase.',
            icon:'error',
        })
          return [];
    }
      
}



const getCurrentConversationInfo = async(uid,converID)=>{
    
    try {
        const dataVariables =  `${uid},${converID}`;
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getConversationInfo/${uid},${converID}`;
        const response = await fetch(url);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        const objReturn = {
            id:Data[0].id,
            conversationID:Data[0].conversationID,
            name:Data[0].name,
            image:Data[0].image,
            status:Data[0].status
        }
        return objReturn;   
    } catch (error) {
        console.error(error)
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la información, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return {};
    }    
}

/**
 * GET new Chats counter by converation
 * uid ==> is the id of the user logged
 */
const getCountChatByConversation = async(uid) => {

    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getMessageByConversationUnreadByUser/${uid}`;
        const response = await fetch(url);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
      
       return Data;
    } catch (error) {
        console.error(error);
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la información, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return [];
    }
}

/**
 * Update messages unread in conversation
 * uid && idConversation are the parameters to recive
 */
//const updateMessagesUnreaded = async(reciberID,conversationID) =>{
    async function updateMessagesUnreaded(reciberID,conversationID){
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/conversationsLastMsgWEB2/${conversationID}`;
        //const header = ;
       const requestOptions = {
            method:'PUT',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({RecibirID:reciberID}),
        }
      
        const response = await fetch(url,requestOptions);
        const res =  await response.json();
        console.log(res);
       return res;

    } catch (error) {
        console.error(error)

    }
}

/**
 * create or getConversation
 */
const createOrGetConversation = async(currentID,recipentID,idCompany,typeUser) =>{
    let BreakException = {};
    try {
        let chatRoom;
        let founded = false;
        let idConversation='';

        
        //find if we have a conversation with this person
        const queryDoc = query(collection(myFirestore,collectionUser,currentID,'Conversations'));
        const colectionUserConver = await getDocs(queryDoc);
        if(colectionUserConver.size > 0){
            colectionUserConver.forEach((doc)=>{
                //console.log(doc.data()['conversationID'])
                if(doc.id === recipentID){
                    idConversation = doc.data()['conversationID'];
                    founded = true;
                    ///
                    /*const conver = query(collection(myFirestore,collectionUser,currentID,'Conversations',recipentID));
                    //aqui vamos a buscar ese idconver en subConver y actualizar o agregar el is current o isCurrent2
                    if(typeUser === 2){
                        
                        
                        updateDoc(conver,{
                            "isCurrentChat2":  true,
                        });
                    }
                    if(typeUser === 3){
                        updateDoc(conver,{
                            "isCurrentChat":  true,
                        });
                    }*/
                }///
                /*else{

                    const conver = query(collection(myFirestore,collectionUser,currentID,'Conversations',doc.id));
                    //aqui vamos a buscar ese idconver en subConver y actualizar o agregar el is current o isCurrent2
                    if(typeUser === 2){
                        
                        
                        updateDoc(conver,{
                            "isCurrentChat2":  false,
                        });
                    }
                    if(typeUser === 3){
                        updateDoc(conver,{
                            "isCurrentChat":  false,
                        });
                    }

                }*/
            })
        }else{
            idConversation = '';
            founded= false;
        }
        
        if(!founded && idConversation==''){
           //create conversation
          
           const newConversation = await addDoc(collection(myFirestore,collectionConversation),{
            "members": [currentID, recipentID],
            "ownerID": currentID,
            "idCompany": idCompany,
            "messages": [],
           });

          /* const convernew = query(collection(myFirestore,collectionUser,currentID,'Conversations',recipentID));
                    //aqui vamos a buscar ese idconver en subConver y actualizar o agregar el is current o isCurrent2
                    if(typeUser === 2){
                        
                        
                        updateDoc(convernew,{
                            "isCurrent2":  true,
                        });
                    }
                    if(typeUser === 3){
                        updateDoc(convernew,{
                            "isCurrent":  true,
                        });
                    }*/

           return newConversation.id;

        }else{
            //send To conversation
            //chatRoom = await getCurrentConversationInfo(currentID,idConversation);
            return idConversation;
        }

    } catch (error) {
        console.error(error)
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la información, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return '';
    }

}

/**GET LOCATION BY CONVERSATION */
const getLocationByChat = async(recipentID)=>{
    try {
        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/getPositionByChat/${recipentID}`;
        const response = await fetch(url);
       const data = await response.json();
       const parseData = JSON.parse(data)
        const {Data} = parseData;
        //console.log('DATA=>',Data);
       return Data;
    } catch (error) {
        console.error(error)
        Swal.fire({
            title:'Advertencia',
            text:'No se pudo obtener la información, intentelo mas tarde, problema de conexíon a la base de datos.',
            icon:'warning',
            confirmButtonText:'Ok',
        })
        return [];
    }
}

/**SEND group Message */
const sendGroupMessageByConversations = async(listConversations, currentIDUser, message)=>{
    try {
        const type = 'text';
        const senderID = currentIDUser;
        const date = Date.now();
        const transformDate = new Date(date);

        const request = doc(myFirestore,'Users',currentIDUser);
        const response = await getDoc(request);
        let nameSender= '';
        if (response.exists()) {
            nameSender = response.data()['name'];  
        }

        if(message !== ""){
            for(let chat = 0; chat < listConversations.length; chat++){
                const newMessage = doc(myFirestore,'Conversations/',listConversations[chat]);
                const union = arrayUnion(...[{
                    "message":message,
                    "senderID": senderID,
                    "timestamp": transformDate,
                    "type": type,
                    "status": 0,
                }]);
                updateDoc(newMessage,{messages:union});
                
                //code to send a chat and arrayUnion

                //notificacion masima
                const conversation = await getDoc(newMessage);
                if(conversation.exists()){
                    let reciberID= '';
                    const member = conversation.data()['members'];
                    if(member[0] !== senderID){
                        reciberID = member[0];
                    }
                    if(member[1]!== senderID){
                        reciberID = member[1];
                    }


                   await sendNotification(nameSender,reciberID,message);

                }


            }
        }else{
                Swal.fire({
                    title: 'Error Al enviar!',
                    text: 'No se puedo enviar el mensaje intento lo mas tarde o vaya directamente a chat y envielo por la sala de chat.',
                    icon:'error',
                })
        }
    } catch (error) {
        console.error(error)
        Swal.fire({
            title: 'Error Al enviar!',
            text: 'No se puedo enviar el mensaje intento lo mas tarde o vaya directamente a chat y envielo por la sala de chat.',
            icon:'error',
        })
    }
}


const sendNotification = async(senderName,reciberID,contentMsg) =>{

    try {

        const url = `https://us-central1-${firebase.nameProyectFirebase}.cloudfunctions.net/app/api/sendNotificationWEB`;

        const infoReciber = doc(myFirestore,'Users',reciberID);
        const respReciber = await getDoc(infoReciber);
        let tokenRe='';
        if (respReciber.exists()) {
            if(respReciber.data()['TypeUser'] === 2 || respReciber.data()['TypeUser'] === 3){
                tokenRe = respReciber.data()['tokenWeb'];
            }else{
                tokenRe = respReciber.data()['token'];
            }
            
        }

        const optionsRequest = {
            method:'POST',
            headers:{"Content-Type":"application/json","Access-Control-Allow-Origin": "*"},
            body:JSON.stringify({senderName:senderName,tokenReciber:tokenRe,contentMsg:contentMsg}),
           //mode:'no-cors'
        };

        const response = await fetch(url,optionsRequest);
        const data = await response.json();

        console.log('NOTIFICACION RESULT => ',data);
        
        
    } catch (error) {
        console.error(error)
    }

}

//**GET CONVERSATIONS BY FIREBASE DIREC */
const getRecentConversationFB = async(uid,searchTex) =>{

        let listConversations = [];
    try {
        if(searchTex === ""){
            listConversations = [];
            /*const myquery = query(collection(myFirestore,collectionUser,uid,'Conversations') , orderBy("timestamp", "desc"));
           // const subCollectionConvers = await getDocs(myquery);
             onSnapshot(myquery, (querySnapshot)=>{
               //console.logg('dasfd');
               
                querySnapshot.forEach((doc)=>{
                    let conversation={
                        id:doc.id,
                        conversationID:doc.data()['conversationID'],
                        image: doc.data()['image'],
                        lastMessage:doc.data()['lastMessage'],
                        name: doc.data()['name'],
                        type:doc.data()['type'],
                        timestamp:doc.data()['timestamp']
                      
                     }
    
                     listConversations.push(conversation);
                });
                console.log("LISTCHAT==ONSnapshot==>",listConversations);
               
            });*/

            /*subCollectionConvers.forEach((doc)=>{
            
                //listConversations.push(doc)
                let conversation={
                    id:doc.id,
                    conversationID:doc.data()['conversationID'],
                    image: doc.data()['image'],
                    lastMessage:doc.data()['lastMessage'],
                    name: doc.data()['name'],
                    type:doc.data()['type'],
                    timestamp:doc.data()['timestamp']
                  
                 }

                 listConversations.push(conversation);

               

            });*/
        
        }
       

        if(searchTex !== '' && searchTex !== null){
            listConversations = [];
            const myquerySearc = query(collection(myFirestore,collectionUser,uid,'Conversations'),orderBy('name'));
            const subCollectionConversSerac = await getDocs(myquerySearc);

            subCollectionConversSerac.forEach((newdoc)=>{
                const nameUser = newdoc.data()['name'];
            if(nameUser.toUpperCase().includes(searchTex.toUpperCase())){
                let converSerc={
                    id: newdoc.id,
                    conversationID: newdoc.data()['conversationID'],
                    image: newdoc.data()['image'],
                    lastMessage: newdoc.data()['lastMessage'],
                    name: newdoc.data()['name'],
                    type: newdoc.data()['type'],
                    timestamp: newdoc.data()['timestamp']
                }
                listConversations.push(converSerc); 
            }
            })
            
        }

        return listConversations;

    } catch (error) {
        console.error(error);
        Swal.fire({
            title: 'Error al servidor',
            text: 'No se pudo conectar o obtener la información por favor intente mas tarde.',
            icon:'error',
        })
        return listConversations;
    }

}



export{getContacts, 
    getConversations, 
    getRecentConversationFB,
    getCurrentConversationInfo,
    getCountChatByConversation,
    updateMessagesUnreaded,
    createOrGetConversation,
    getLocationByChat,
    sendGroupMessageByConversations,
    sendNotification
}
