

import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../auth/authContext';
import { getAllEvidences, getAllIncidencias } from '../../helpers/homeFunc';
import { CircleGif } from '../CircleGif';

 const HomeCards = () => {

    //const [totalMsg,setTotalMsg] = useState(0);
    const [totalEvi,setTotalEvi] = useState(0);
    const [totalInc,setTotalInc] = useState(0);
    //const [totalUser,setTotalUser] = useState(0);
    //constates useState para los demas contadores aun faltan
    const {currentUser} = useContext(AuthContext);
   

    useEffect(()=>{
        getAllEvidences(currentUser.idCompany).then((evidences)=>{
            setTotalEvi(evidences)
        });
        getAllIncidencias(currentUser.idCompany).then((incidences)=>{
            setTotalInc(incidences);
        });
    },[])

    return (
        <>
        <div className='row'>
            <div className='col s12 m3 l4'></div>

            <div className='col s6 m3 l2'>
            {/*    
            <Link to='/chat'>
                    <div className="card card-gradient-msn border-10 z-depth-5 animate__animated animate__backInDown animate__delay-1s" >
                        <div className="card-content white-text waves-effect waves-block waves-light center ">
                            <i className='material-icons medium'>question_answer</i>
                            <p><b><span className='number-Card'>{ totalMsg }</span></b></p>
                            <h5 className='title'>Mensajes</h5>
                        </div>
                    </div>
            </Link> */}
             <Link to='/incidencias'>
                    <div className="card card-gradient-inci border-10 z-depth-5 animate__animated animate__backInDown animate__delay-1s sizeCardHome">
                        <div className="card-content white-text waves-effect waves-block waves-light center ">
                            <i className='material-icons medium'>collections</i>
                            <p><b><span className='number-Card'>{  totalInc }</span></b></p>
                            <h5 className='title'>Incidencias</h5>
                        </div>
                    </div>
                </Link>


            </div>
            <div className='col s6 m3 l2'>
                
            <Link to='/evidencias'>
                    <div className="card card-gradient-evi border-10 z-depth-5 animate__animated animate__backInUp animate__delay-1s sizeCardHome">
                        <div className="card-content white-text waves-effect waves-block waves-light center ">
                            <i className='material-icons medium'>assignment</i>
                            <p><b><span className='number-Card'>{  totalEvi }</span></b></p>
                            <h5 className='title'>Evidencias</h5>
                        </div>
                    </div>
                </Link>

            </div>

            <div className='col s12 m3 l4'></div>
        </div>

       {/* <div className='row'>
        <div className='col s12 m2 l3'></div>

        <div className='col s6 m4 l3'>
                
            <Link to='/incidencias'>
                    <div className="card card-gradient-inci border-10 z-depth-5 animate__animated animate__backInDown animate__delay-1s">
                        <div className="card-content white-text waves-effect waves-block waves-light center ">
                            <i className='material-icons medium'>collections</i>
                            <p><b><span className='number-Card'>{ totalInc }</span></b></p>
                            <h5 className='title'>Incidencias</h5>
                        </div>
                    </div>
                </Link>
        </div>

        <div className='col s6 m4 l3'>
            
        <Link to='/usuarios'>
                    <div className="card card-gradient-usu border-10 z-depth-5 animate__animated animate__backInUp animate__delay-1s">
                        <div className="card-content white-text waves-effect waves-block waves-light center ">
                            <i className='material-icons medium'>people</i>
                            <p><b><span className='number-Card'>{ totalUser }</span></b></p>
                            <h5 className='title'>Usuarios</h5>
                        </div>
                    </div>
                </Link>

        </div>

        <div className='col s12 m2 l3'></div>

        </div>
        */}
      
        </>
    )
};


export default HomeCards;