

import React from 'react'
import { useEffect, useState } from 'react/cjs/react.development'
import { getCurrentIncidence } from '../helpers/incidenceFunc';



export const useCurrentInci = (id,idCompany) => {
    
    
    const [stateInicident,setIncident] = useState({
        incident:[],
        loadingImg:true
    });

    useEffect(()=>{
        setIncident({
            incident:[],
            loadingImg:true
        });
        getCurrentIncidence(id,idCompany).then((res)=>{
            setIncident({
                incident:res,
                loadingImg:false
            });
        })
    },[id]);

    return stateInicident;
}
