import { types } from "../types/type";



export const authReducer = (state={}, action) => {
    
    
    switch (action.type) {
        case types.login:
                return {
                    ...action.userData,
                    logged:true
                }
         
        case types.logout:
            return {
                logged:false,
            }
         
    
        default:
            return state;
         
    }
}