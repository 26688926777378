import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/type';
//import Select from 'react-select'
import { useGetTypeUserList } from '../../hooks/hooks_register';
import { getTypeUser, registerUser, updateUser } from '../../helpers/registerFunc';
import {getUser} from '../../helpers/usersFunc';
import M from 'materialize-css';
import Swal from 'sweetalert2';

export const EditUserForm = () => {

    const param = useParams();
    console.log(param);
    // Datos del usuario
    const email = param.usuario;
    const initialValues = {id:"", user:"", email:"", password:"", confirmPassword:"", typeUser:"", active:""};
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(()=>{
        let isMounted = true;
        getUser(email).then((result)=>{
            console.info("result=>",result);
            // console.log("result[0].typeUser=>" + result[0].typeUser);
            setFormValues({id:result[0].id, user:result[0].name, email:result[0].email, 
                password:"", confirmPassword:"", 
                typeUser:result[0].typeUser, active:result[0].active});
        });
        return () => {
            isMounted = false;
            };
    },[]);
    // FIN

    const [selectState, setSelectState] = useState(0);
    const handleStateChange = () => {
        let count = 0;
        count++;
        setSelectState(count);
    };

    const {loading, listTypeUser} = useGetTypeUserList(selectState);
    const listaValores = listTypeUser;
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        console.log(e.target.value);
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    }

    /*
    const validate = async (values) => {
        let procesoOk = true;
        const errors = {};
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!values.user) {
            errors.user = "El usuario es requerido.";
            procesoOk = false;
        }
        if (!values.email) {
            errors.email = "El email es requerido.";
            procesoOk = false;
        } else if (!regex.test(values.email)) {
            errors.email = "El email no tiene el formato correcto.";
            procesoOk = false;
        }
        if (!values.password) {
            errors.password = "El password es requerido.";
            procesoOk = false;
        } else if (values.password.length < 6) {
            errors.password = "El password debe tener como mínimo 6 caracteres.";
            procesoOk = false;
        } else if (values.password != values.confirmPassword) {
            errors.confirmPassword = "No coincide el password con el de confirmación.";
            procesoOk = false;
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = "La confirmación del password es requerida.";
            procesoOk = false;
        }
        if (!values.typeUser) {
            errors.typeUser = "El tipo de usuario es requerido.";
            procesoOk = false;
        }
        return [errors, procesoOk];
    }
    */

    useEffect(() => {
        // console.log("UseEffect");
        let isMounted = true;
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);
        return () => {
            isMounted = false;
            };
        // Inicializamos los controles
    },[]);

    const validate = async (values) => {
        let procesoOk = true;
        const errors = {};
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        if (!values.typeUser) {
            errors.typeUser = "El tipo de usuario es requerido.";
            procesoOk = false;
        }
        return [errors, procesoOk];
    }

    //Register
    const UpdateUser_OnClick = async() => {
        const result = await validate(formValues);
        const resErrors = result[0];
        const contErrors = result[1];
        setFormErrors(resErrors);

        if (contErrors == true) {
            const resulUpdated = await updateUser(formValues.id, formValues.email, formValues.password, formValues.user, 
                "", "Q4010", formValues.typeUser, formValues.active);
            if (resulUpdated) {
                Swal.fire({
                    title:'Aviso',
                    text:'Usuario actualizado.',
                    confirmButtonText:'Ok',
                    icon:'success',
                  });
            } else {
                Swal.fire({
                    title:'Aviso',
                    text:'El usuario no se actualizó.',
                    confirmButtonText:'ok',
                    icon:'warning',
                  });
            }
        }

    }

    return (
        <div className='containerUser'>
        <div className=' contentForm'>
            <div className='col s12 m12 l12 center'>
                <div className='row center'><h4>Editar usuario</h4></div>
                <div className='grettingsDiv'>
                    
                </div>
                <form className='col s12 m12 l12'>
                    <div className='row'>
                        <div className="input-field  blue-grey lighten-5 formInput">
                            <i className="tiny material-icons prefix iconPositionInput ">person</i>
                            <input name="user" placeholder="Usuario" type="text" className="validate" readOnly 
                                value={formValues.user} onChange={handleChange} />
                        </div>
                        <p className="red-text">{formErrors.user}</p>
                        <div className="input-field  blue-grey lighten-5 formInput">
                            <i className="tiny material-icons prefix iconPositionInput ">email</i>
                            <input name="email" placeholder="Correo electrónico" type="email" className="validate" readOnly 
                                value={formValues.email} onChange={handleChange}/>
                        </div>
                        <p className="red-text">{formErrors.email}</p>

                        <div className="input-field blue-grey lighten-5 formInput">
                            <i className="tiny material-icons prefix iconPositionInput ">ic_storage_black_36dp</i>
                            <select onClick={handleStateChange} name="typeUser" 
                            value={formValues.typeUser}  
                            onChange={handleChange} required className="browser-default">
                                <option key='0' value='0'>Seleccione una opción</option>
                                <option key='1' value='1'>SuperAdmin</option>
                                <option key='2' value='2'>Admin</option>
                                <option key='3' value='3'>Jefe Flotilla</option>
                                <option key='4' value='4'>Monitorista</option>
                                <option key='5' value='5'>Operador</option>
                            </select>
                            
                        </div>
                        <p className="red-text">{formErrors.typeUser}</p>


                        <div className="input-field blue-grey lighten-5 formInput">
                            <i className="tiny material-icons prefix iconPositionInput "></i>
                            <select name="active" value={formValues.active} onChange={handleChange} className="browser-default"  
                                required placeholder='Activo'>
                                <option key='1' value='1'>Activo</option>
                                <option key='0' value='0'>Inactivo</option>
                            </select>        
                        </div>


                    </div>
                    <div className='registerDiv'>
                        <button 
                            className='btn waves-effect waves-light btnFromRegisterCreate' 
                            onClick={UpdateUser_OnClick} 
                            type='button' name='Crear'>
                                Actualizar
                        </button>
                        <NavLink to="/usuarios" className='btn waves-effect waves-light btnFromRegisterCancel'>
                            Regresar
                        </NavLink>
                        
                    </div>
                </form>
                      
            </div>
            
        </div>
        </div>
     
    )
}
