import { useContext } from "react"
import { Navigate } from "react-router-dom";
import { AuthContext } from "../auth/authContext";

export const PublicRoute = ({ children }) => {
    const {currentUser} = useContext(AuthContext);

    //const {pathname, search } = useLocation();
    return currentUser.logged ? <Navigate to="/" />  : children
    
}