import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import { MapPopup } from '../MapPopup';
import M from 'materialize-css';
import {getEvidences} from '../../helpers/evidenceFunc';
import { CircleGif } from '../CircleGif';
import { createOrGetConversation, sendGroupMessageByConversations } from '../../helpers/chatFunc';
import { AuthContext } from '../../auth/authContext';
//import { useGetEvidence } from '../../hooks/hooks_evidence';

export const EvidencesHome = () => {

    const itemsPerPAge = 8;
    let count = 0;
    
     //Global info user dispatch
     const {currentUser} = useContext(AuthContext);
    
    const [listEvidence,setListevidence] = useState([]);
   // const [stateLatLng,setLatLng] = useState('');
    const [pageCount,setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [serachText, setSerachText] = useState("");
    const [locations,setLocations] = useState({
        latitude:undefined,
        longitude:undefined
    });
    const [stateDataToMap, setDataToMap] = useState({
        
    });

    
    useEffect(() => {

        let isMounted = true;
     let modalM = document.querySelectorAll('.modal');
     M.Modal.init(modalM);
     return () => {
        isMounted = false;
        };
     //M.FormSelect(select);
       
     }, []);

     //list of evidences
    const [listEvidenceState,setListEvidences] = useState({
        listEvidenceData:[],
        loading:true,
    });
    
    useEffect(()=>{
       let isMounted = true;
        getEvidences(serachText,currentUser.idCompany,currentUser.id,currentUser.typeUser).then((result)=>{
            console.log('Evidencias=>',result);
            setListEvidences({
                listEvidenceData:result,
                loading:false,
            })
        })
        return () => {
            isMounted = false;
            };
    },[page]);


    const {listEvidenceData,loading} = listEvidenceState;
    const getListEvidence =listEvidenceData;
    console.log(getEvidences);

    
    const onChangeSerachTextEvi = (e) => {
        //e.preventDefault();
        //const searchText = e.target.value;
        setSerachText(e.target.value);
    }


     /**
     * GET List of evidences
     */
    const retriveEvidences = ()=>{
        //const endOffset = (page) + itemsPerPAge;
        //if(listEvidenceData.length > 0){
            const items = getListEvidence.slice((page-1)*itemsPerPAge,(page-1)*itemsPerPAge+itemsPerPAge);
            setListevidence(items);
            setPageCount(Math.ceil(getListEvidence.length/itemsPerPAge));
        /*}else{
            setListevidence([]);
            setPageCount(0);
        }*/
        
    }
   /**
    * update list each click or page change
    */
    useEffect(() => {
        let isMounted = true;
        retriveEvidences();
        return () => {
            isMounted = false;
            };
       
    }, [page,itemsPerPAge,listEvidenceState]);

    //serachForm
    const searchEvidence = (event) =>{

        event.preventDefault();

        setListEvidences({
            listEvidenceData:[],
            loading:true,
        });

        getEvidences(serachText,currentUser.idCompany).then((result)=>{
            console.log('DATA AFTER SEARCH => ',result);
            setListEvidences({
                listEvidenceData:result,
                loading:false,
            })
        })

        if(listEvidenceState.listEvidenceData.length > 0){
            retriveEvidences();
        }

        

    }

  

    
    //page change btn
    const handlePageChange = (e) => {
        e.preventDefault();
        //console.log(e.target.id);
        const currentPage = parseInt(e.target.id);
        setPage(currentPage);
      };


      //backArrow
    const handlerBackArrowPage= () => {
        const arrowBack = document.getElementById('backli');
            if(page===1){
                arrowBack.classList.add('disabled');
                setPage(1);
            }
            if(page > 1){
                arrowBack.classList.remove('disabled');
                const backPage = page  - 1;
                setPage(backPage);
            }

    }

    //Next Arrow
    const handlerNextArrowPage=()=>{
        const netxArrow = document.getElementById('nextli');
        if(page===pageCount){
            netxArrow.classList.add('disabled');
            //setPage(pageCount);
        }
        if(page < pageCount){
            netxArrow.classList.remove('disabled');
            const nextPage = page  + 1;
            setPage(nextPage);
        }
    }


    
    console.log('List evidence per page ===>',listEvidence);
    const [chatState,setChatStat] = useState('');
    
    //getConversationID
    const handlerCreateOrGetChat = (e) =>{
        e.preventDefault();
        let recipentID = e.target.id;
        //console.log(recipentID);
       createOrGetConversation(currentUser.id,recipentID,currentUser.idCompany).then((res)=>{
            console.log(res);
           setChatStat(res);
           if(res !== ''){
            showPopupMessage(res);
           }else{
               //erro pop
           }
        })
    }

    //showPopMessage
    const showPopupMessage = (chatID)=>{
        Swal.fire({
        input: 'textarea',
        //inputLabel: 'Message',
        title:'Enviar Mensaje',
        //text:name,
        inputPlaceholder: 'Escriba su mensaje aquí...',
        inputAttributes: {
          'aria-label': 'Escriba su mensaje aquí',
          
        },
        
        showCancelButton: true,
        confirmButtonText:'Enviar',
        cancelButtonText:'Cancelar',
       }).then(async(result)=>{
        if(result.dismiss === 'cancel'){
          setChatStat('');
        }
        else if(result.dismiss === 'backdrop')
        {
            setChatStat('');
        }
        else{
            if(result.value !== ""){
                
                let listChatOnlyOne = [];
                listChatOnlyOne.push(chatID);
                //console.log('good',result.value);
                //const {selectedChats} = selectChat;
                //console.log('sendMessage to CHAT=>',listChatOnlyOne);
                await sendGroupMessageByConversations(listChatOnlyOne,currentUser.id,result.value);
                 setChatStat('');
                Swal.fire({
                    icon:'success',
                    title:'Entrega exitosa',
                    text:'El mensaje fue enviado con exito',
                });
                //funcion para enviar mensajes por n conversaciones
    
           }
    
           if(result.value === ""){
                Swal.fire({
                    icon:'warning',
                    title:'Aviso',
                    text:'El mensaje no puede ir vacio.'
                });
               
            }
        }
           
       });
    }


    //get Location
    const handlerLocation = (e) =>{
        e.preventDefault();
       // console.log(e.target.id);
        let getLocations = e.target.id.split('|');
        setLocations({
            latitude:parseFloat(getLocations[0]),
            longitude:parseFloat(getLocations[1])
        });
        
        setDataToMap({
            user_name:getLocations[2],
        vehicleNumber:getLocations[3],
        distance:parseFloat(getLocations[4]),
        dateString:getLocations[5],
        name:getLocations[6]
        });
        //setLatLng(e.target.id);
    }

   

    //RENDER PAGINATION
    const renderPagination = () =>{
        let listPageCount= [];
        for (let index = 0; index < pageCount; index++) {
         
            if(index === 0){
                let num = 1;
                listPageCount.push(<li className={`${page === num ? 'active' : ''}`}  key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)
                
            }else{
                let num = index + 1;
               listPageCount.push(<li className={`${page === num ? 'active' : ''}`} key={index}><Link to='' onClick={handlePageChange} id={num}>{num}</Link></li>)
               
            }
            
        }

        return listPageCount;
    }


    //renderTable Information 
    const listTableEvidence  =() =>{
        if(listEvidence.length > 0){
            return (
                <div className='contentTable'>
                <table className='striped highlight  responsive-table centered tableFont'>
                <thead className='borderbotomLine'>
                    <tr>
                    <th className='center'>Fecha</th>
                    <th className='center'>Operador</th>
                    <th className='center'>Unidad</th>
                    <th className='center'>Origen</th>
                    <th className='center'>Destino</th>
                    <th className='center'>Orden Servicio</th>
                    <th className='center'>Cliente</th>
                    <th className='center'>Num</th>
                    {/*<th className='center'>Observación</th>*/}
                    <th className='center' colSpan={3}>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                         
                          listEvidence.map((evidence)=>{
                        count++;
                      
                        return <tr className={evidence.cantNewEvidences > 0 ? 'newsEvidences' :''}
                        id={count % 2 === 0 ? 'rowHighLigth' : ''}
                         key={`${evidence.dateString}-${count}`}>
                            <td>{evidence.dateString}</td>
                            <td>{evidence.userName}</td>
                            <td>{evidence.vehicleNumber}</td>
                            <td>{evidence.origin}</td>
                            <td>{evidence.destination}</td>
                            <td>{evidence.serviceGuide}</td>
                            <td>{evidence.name}</td>
                            <td>{evidence.numEvidences}</td>
                        {/* <td>{evidence.observation !== "" ? evidence.observation.substring(0,16) : 'NA'}</td> 
                                cantNewEvidences
                        */}
                            <td>
                                <div className='optionsEvi'>
                                <Link to='' id={evidence.userUid} onClick={handlerCreateOrGetChat} style={{marginRight:'10px'}}><i className='material-icons iconsList' id={evidence.userUid} style={{pointerEvents:'none'}}>question_answer</i></Link>
                                <Link to={`/evidencias/${evidence.serviceDetail_ID}`} id={evidence.id}  style={{marginRight:'10px'}}><i className='material-icons iconsList' id={evidence.id}>collections</i></Link>
                                <Link to="" data-target="modal1" className=' modal-trigger' id={`${evidence.statusLat}|${evidence.statusLon}|${evidence.userName}|${evidence.vehicleNumber}|${evidence.distanceRemain}|${evidence.dateString} | ${evidence.name}`}
                                    onClick={handlerLocation}>
                                    <i className='material-icons iconsList' style={{pointerEvents:'none'}}>pin_drop</i>
                                </Link>
                                </div>
                   
                            </td>
                            </tr>
                    })
                    }
                </tbody>
            </table>
            <div className='row center'>
                <ul className='pagination'>
                    <li className={`${page===1 ? 'disabled' : ''}`} key='back' id='backli'><a href="#!" id='back' onClick={handlerBackArrowPage}><i className="material-icons">chevron_left</i></a></li>
                        {renderPagination()}
                    <li className={`${page===pageCount ? 'disabled' : ''}`} key='next' id='nextli'><a href="#!" id='next' onClick={handlerNextArrowPage}><i className="material-icons">chevron_right</i></a></li>
                </ul>
            </div>
        </div>
            )
        }else{}
    }



    return (
        <div className=''>
        <div className='containerEvid'>
                
                <div className="" style={{background:'#5E5E68'}}>
                    <form className='center' style={{padding:'10px 0px'}} onSubmit={searchEvidence}>
                        <div className="input-field inputSerach ">
                            <i className="material-icons  prefix " style={{color:'#A3A3A9', left:'0px', top:'10px'}}>search</i>
                            <input id="search" type="text"  placeholder='Buscar'  className='inputSerachBorderBottom' value={serachText} onChange={onChangeSerachTextEvi}/>
                           
                        </div>
                    </form>
                </div>

                <div className='row center'><h4>EVIDENCIAS</h4></div>
                    
                    { loading ===true ? <div className='row center'><CircleGif /></div> : listTableEvidence() }
       
        </div>
        
            {/*renderMapPop(stateLatLng)*/}
        
            {<MapPopup latitude={locations.latitude} longitude={locations.longitude} infoData={stateDataToMap}/>}

        </div>

    )
}



