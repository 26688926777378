

import React from 'react'
import { EvidenciaInfo } from '../components/Evidences/EvidenciaInfo'

export const EvidenceInfoPage = () => {
    return (
       <EvidenciaInfo />
    )
}
