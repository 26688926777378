

import React, { useContext, useEffect, useState } from 'react'
import {GoogleMap, Marker, useLoadScript ,InfoWindow,LoadScript} from '@react-google-maps/api';
import { CircleGif } from '../CircleGif';
import { AuthContext } from '../../auth/authContext';
import { getPositions } from '../../helpers/mapFunc';


export const BigMap = () => {

    //const zoom = 5;

    
  //Global info user dispatch
  const {currentUser} = useContext(AuthContext);
  let counter=0;
    const [listOperators, setListOperators] = useState({
        listOperatorsd:[],
        loading:true
    });

    const [activeMarker, setActiveMarker] = useState(null);

    const [zoom,setZoom] = useState(5);
  
    const { isLoaded } = useLoadScript({
        id:'script-id',
       language:'es',
        googleMapsApiKey: "AIzaSyCUuTHJGUQ8kkpNFzDB7vjuS8vixaI3o58",
      })

      
    const containerStyle = {
        width: '100vw',
        height: '95vh'
      };

      const center = {
        lat: 21,//20.214526,
        lng: -100//-101.122280,
      };
      
    //de preuba
    useEffect(()=>{

      let isMounted = true;
        getPositions(currentUser.id).then((result)=>{
            //console.log(result);
            setListOperators({
                listOperatorsd:result,
                loading:false,
            });
        });
        return () => {
          isMounted = false;
          };
        /*let listOperators = [
            {userName:'Juan Sanchez',userId:'asdfasf241231',vehicleNumber:'T450',latitude:20.697225,longitude:-103.330953,origin:'Queretaro',destination:'Guadalajara',distance:790.0,dateString:'10/10/2022 07:46:05'},
            {userName:'Jose Sanchez',userId:'gfhfgh45645h',vehicleNumber:'T455',latitude:21.116206,longitude:-101.683019,origin:'Queretaro',destination:'Guadalajara',distance:790.0,dateString:'10/10/2022 07:46:05'},
            {userName:'Jorge Sanchez',userId:'543324gfsdfgdf',vehicleNumber:'T490',latitude:21.014419,longitude:-101.267162,origin:'Queretaro',destination:'Guadalajara',distance:790.0,dateString:'10/10/2022 07:46:05'},
            
        ];*/
        
    },[]);
    const {listOperatorsd,loading} = listOperators;

    const handlerActiveMarker = (marker) =>{
        //marker.preventDefault();
        //console.log(marker);
        if (marker === activeMarker) {
          return;
        }
        setActiveMarker(marker);
      }

      function handleZoomChanged(){
          
        console.log(this.getZoom());
        //this refers to Google Map instance
        //setZoom(this.getZoom());
      }

      const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        listOperatorsd.forEach(({ latitude, longitude}) => bounds.extend({ lat: latitude, lng: longitude }));
        map.fitBounds(bounds);
      };

      //RENDER MAP 
      const renderMap = ()=>{
          console.log(listOperatorsd);
       if(!isLoaded){
           return <div>EL Mapa no se puede cargar, ocurrio un error.</div>;
        }else{
            
           return <GoogleMap
                    id='map-page'
                    onLoad={handleOnLoad}
                    mapContainerStyle={containerStyle}
                    zoom={zoom}
                    //defaultZoom={zoom}
                    //center={center}
                    //onZoomChanged={handleZoomChanged}
                    onClick={()=> setActiveMarker(null)}       
            >

            {listOperatorsd.map( ({user_name,user_id,vehicleNumber,latitude,longitude,origin,destination,distance,dateString}) =>{
                
                             return <Marker 
                              icon={{url:'/assets/camion.png', scaledSize:new window.google.maps.Size(30,30)}}
                             key={user_id}
                            position={{ lat: latitude, lng: longitude }}
                            onClick={()=>{
                              //e.preventDefault();
                             handlerActiveMarker(user_id)
                            }} 
                         >
                  
                           {
                             activeMarker === user_id ? ( 
                             <InfoWindow 
                             
                             onCloseClick={()=>setActiveMarker(null)}>
                               <div className='formatInfoMarker'>
                                 <span style={{fontSize:'14pt', fontWeight:'bold'}}>Operador: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{user_name}</span>
                                 <br/>
                                 <br/>
                                 <span style={{fontSize:'14pt', fontWeight:'bold'}}>Unidad: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{vehicleNumber}</span>
                                 <br/>
                                 <br/>
                                 <span style={{fontSize:'14pt', fontWeight:'bold'}}>Cliente: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{vehicleNumber}</span>
                                 <br/>
                                 <br/>
                                 
                                 <span style={{fontSize:'14pt', fontWeight:'bold'}}>Fecha: </span><span style={{fontSize:'14pt', fontWeight:'normal'}}>{dateString}</span>
                            
                               </div>
                              
                             </InfoWindow> ): null
                           }
                           
                         </Marker> 
            })}
        </GoogleMap>
        
        } 

    }

    return (
        <div className='contentMap'>
            
            {loading ===true  ? <div className='centerGif center'><CircleGif /></div> : renderMap()}
           { /*<LoadScript id='script-loader' googleMapsApiKey='AIzaSyDnwZ0_kTdjOCodwYWcPuNOU_cfnujxSX4' >{renderMap()}</LoadScript>*/}
        </div>
    )
}
