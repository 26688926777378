

import React from 'react'
import { Home } from '../components/Home/Home'
import '../css/Home.css';


export const HomePage = () => {
    return (
        <div>
            
            <Home/>
        </div>
    )
}
