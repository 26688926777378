

import React from 'react'

export const ChatBlankPage = () => {
    return (
        <div className='center '>
        <h4 className='title'>DRIVELOG</h4>
        <h5 className=''>Conversa con tu equipo.</h5>
        <div className='container' id='fondoChat'>
            <img alt='homeCHat' src={`/assets/fondochat.jpg`} className='responsive-img'/>
        </div>
    </div>
    )
}
