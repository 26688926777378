
import React from 'react'
import  HomeCards   from './HomeCards'

export const Home = () => {
    return (
        <>
        
        <div className='body-home'>
       
                    <div style={{paddingTop:'5%'}}>
                        <HomeCards />
                    </div>

        </div>
        </>
    )
}
