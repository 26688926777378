


import { useState, useEffect, useMemo } from 'react'
import { getContacts, getConversation, getConversations, getCurrentConversationInfo, getListenNewMessages, getLocationByChat, updateMessagesUnreaded } from '../helpers/chatFunc';
//import { collection, query, where, getDocs,orderBy, doc,getDoc, onSnapshot,  collectionGroup, CollectionReference } from "firebase/firestore";
//import { myFirestore } from '../config/ConfigFirebase';


//Return ConversationsCHatLISt
 /*const useGetChatList = (uid,stateList,searchText) => {
   const [chatsState,setChatState] = useState({
      listChats:[],
      loading:true,
   });
      
   //make petition to firebase
   useEffect( () => {
      
      if(stateList === 'Linchats') {
         
        // const interval = setInterval(()=>{
            //
            
            getConversations(uid,searchText).then( (conversation) => {
               
               setChatState({
                listChats:conversation,
                loading:false,
                })
             });
       // },3000)
   
       //return ()=> clearInterval(interval);
      }
     
   },[stateList,searchText]);//cambiar por searchText

   return chatsState;
}

//idCompany & userType ==> RETURN USERS
const useGetUsersChat= (idCompany, uid,userType,stateList) => {
  
   const [usersState, setUserState] = useState({
      listUsers:[],
      loadingCon:true,
  }); 

  useEffect(  () => {
    getContacts(idCompany,uid,userType).then((users)=>{
       
        setUserState({
         listUsers:users,
            loadingCon:false,
        });
    });
 
   },[stateList]);//cambiar por searchText

      return usersState;
}*/


//GET CONVERSATION
const useChatCurrentConversation =(uid,conversationID)=>{
   const [currentChatState,setCurrentChatState] = useState({
      conversation:{},
      loading:true
   });
   useEffect( ()=>{
      /*getConversation(conversationID).then( (conver)=>{
         setCurrentChatState({
            conversation:conver,
            loading:true});
      });*/
      getCurrentConversationInfo(uid,conversationID).then((info)=>{
         
         setCurrentChatState({
            conversation:{...info},
            loading:true
         })
      })
   },[conversationID]);
   
   return currentChatState;
}

//Listen New messages Faltan cosas
/*const useListenNewMessages = (conversationID,currentMsgLenght) =>{
   let listCurrentMessage = [];
   currentMsgLenght.forEach(element => {
      listCurrentMessage.push(element);
   });
   const [newStateMessages, setNewStateMessages]= useState({
      msg:[],
      //loadingMessages:false,
   });

   useEffect ( ()=>{
      getListenNewMessages(conversationID).then( (messages)=>{
         if(listCurrentMessage.length < messages.length){
            listCurrentMessage.length = messages.length;
            setNewStateMessages({
               msg:messages
               //loadingMessages:true,
            })
         }
      })
   },[conversationID,])

   return newStateMessages;
   
}*/


/**
 * Get total count msg unreaded by conversation
 */

const useGetMsgUnreadedByConversation = (uid,conversationID) =>{
      //const []
}

const useUpdateMessagesByEnterConversations = (uid,conversationID) =>{
   const [stateLoading,setStateLoading] = useState({loadingUp:false});
   
   useEffect( ()=>{
      setStateLoading({loadingUp:false})
      if(conversationID !== "" || conversationID !== null){
         updateMessagesUnreaded(uid,conversationID).then((val)=>{
            setStateLoading({
               loadingUp:true
            });
   
         })
      }
      
   },[conversationID])
   return stateLoading;
}

/**
 * GET LAST CURRENT LOCATION BY CHATUSER
 */
const useGetLocationByChat = (recipentID)=>{
   const [location,setLocation] = useState({});
   useEffect(()=>{
      if(recipentID === ''){
         getLocationByChat(recipentID).then((val)=>{
            //console.log('valLocation',val);
            setLocation({...val});
         });
      }
   },[recipentID])

   return location;
}



export { 
   useChatCurrentConversation,
   useUpdateMessagesByEnterConversations,
   useGetLocationByChat
}
